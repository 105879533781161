import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img_stock/bio_chem_1.JPG";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Location</h2>
      <div>
        <GridContainer>
        
        <GridItem xs={12} sm={12} md={12} lg={12} style={{height:'50%'}}>
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13394.329422719917!2d72.8619719!3d32.935631!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x552a04fb228e0f86!2sFS%20Lab%20Services!5e0!3m2!1sen!2s!4v1595870714819!5m2!1sen!2s" style={{width:'100%', height:'35em'}}frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" />
             
          </GridItem>
          </GridContainer>
      </div>
    </div>
  );
}
