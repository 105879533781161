import React, {Fragment} from 'react';
import {Text, View, StyleSheet, Font } from '@react-pdf/renderer';

const InvoiceTableRow = ({items}) => {
    const rows = items.map( item => 
        <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        fontSize:7,
       
        }} key={item.test_name}>
             <Text style={{ 

        width: '30%',
        textAlign: 'left',
        paddingLeft: 8,
        padding:10,
        fontFamily:"Calibri Regular",
        height:'100%',
        textAlign:'center',
        fontSize:9
        }}>{"COVID-19 Rapid Antigen Test (RAT)"}</Text>
         <Text style={{ 
                 
        width: '25%',
        textAlign: 'center',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        paddingTop:5,
        fontWeight:'',
        backgroundColor:item.result=="Negative" ? '#c3ffbd' : '#ff9f9e',
        fontSize:9,
        height:'100%',
        paddingLeft: 8,
        fontFamily:"Helvetica-Bold",
        
        }}>{item.result}</Text>
         <Text style={{ 
                 
        width: '15%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        fontFamily:"Calibri Regular",
        fontSize:7,
        paddingLeft: 8,
        height:'100%'
        
        
        }}>{item.ref_range}</Text>
      
         <Text style={{ 
                 
        width: '15%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 0.5,
        height:'100%',
        fontFamily:"Calibri Regular",
        fontSize:7,
        paddingLeft: 5,
        
        
        }}>{item.prev1}</Text>
            <Text style={{ 
                 
        width: '15%',
        height:'100%',
        textAlign: 'left',
        borderLeftWidth:0.5,
        fontSize:7,
        fontFamily:"Calibri Regular",
        textOverflow: 'auto',
        paddingLeft: 8
        
        }}>{item.prev2}</Text>
           
        </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow