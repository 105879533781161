import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import HelpIcon from '@material-ui/icons/Help';
import { isMobile } from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReceiptIcon from '@material-ui/icons/Receipt';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';

import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';
import Invoice from './PDF/Invoice.js'


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import DialogActions from "@material-ui/core/DialogActions";

import Slide from "@material-ui/core/Slide";

import { PDFViewer } from '@react-pdf/renderer'

import { Page, Text, View, Document, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from '@date-io/date-fns';

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { GridList } from '@material-ui/core';
import CovidTemplate from './Covid_PDF/CovidTemplate'
import TemplateCertificate from './TemplateCertificate/TemplateCertificate'

import CertificateTemplate from './Certificate/CertificateTemplate'

import SignCovidTemplate from './Sign_Covid_PDF/SignCovidTemplate'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Covid_AntiGen from './Covid_AntiGen/CovidTemplate'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id = localStorage.getItem('patient_name') + ""
var invoice = id.toLowerCase().includes('mpl-') ? [
  { id: 'patient_invoice', label: 'View Patient Invoice' }
]
  : [
    { id: 'invoice', label: 'View Invoice' }
  ]

const columns =
  localStorage.getItem('role') == 'Admin' ?
    [{ id: 'id', label: 'Invoice ID' },
    { id: 'name', label: 'Patient Name' },
    { id: 'title', label: 'Test Name' },
    { id: 'code', label: 'Test Code' },
    { id: 'status', label: 'Status' },
    { id: 'download', label: 'View Report' },
    ...invoice,

    ]
    :
    [{ id: 'id', label: 'Invoice ID' },
    { id: 'name', label: 'Patient Name' },
    { id: 'title', label: 'Test Name' },
    { id: 'code', label: 'Test Code' },
    { id: 'status', label: 'Status' },
    { id: 'download', label: 'View Report' },
    ]



const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}

export default function Lab_Tests() {
  const classes = useStyles();
  var date = new Date()
  var temp_role = localStorage.getItem('role')
  if (temp_role == 'Panel') {
    date.setDate(date.getDate() - 1);
  }
  if (temp_role == 'Admin') {
    date.setDate(date.getDate() - 1);
  }

  if (temp_role == 'Patient') {
    date = new Date('1/1/2019')
  }
  var svgString2Image = (svgString, width, height, format, callback) => {

    var svgStringFormat = window.atob(svgString)
    var temp = svgStringFormat.split(`<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">`)
    // set default for format parameter
    format = format ? format : 'png';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp[1])));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL('image/' + format);
      // pass png data URL to callbac
      callback(pngData)
    }; // end async
    image.src = svgData

  }
// Covid-19 RNA BY PCR
  const [selected, setSelected] = React.useState({
    reports: []
  })
  // Covid-19 RAT
  const [selectedRAT, setSelectedRAT] = React.useState({
    reports: []
  })

  const [temp_selected, setSelectedTemp] = React.useState({
    reports: []
  })
  const [selectedCovid, setSelectedCovid] = React.useState({
    reports: []
  })
  // COVID-19 RNA BY PCR
  const [OpenCovidTemplate, setCovidTemplate] = React.useState(false)
  // COVID-19 RAT
  const [OpenCovidRAT, setOpenCovidRAT] = React.useState(false)

  const [patient_invoice, setPatientInvoice] = React.useState(false)
  const [from, setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel, setPanel] = React.useState('All')
  const [page, setPage] = React.useState(0);

  const [certificate, setcertificate] = React.useState(false)
  const [selectedCertificate, setSelectedCertificate] = React.useState({})
  // COVID-19 RNA BY PCR
  const [covidButton, setCovidButton] = React.useState(false)
  // COVID-19 RAT
  const [covidRATButton, setCovidRATButton] = React.useState(false)

  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [SearchRecords, setSearchRecords] = React.useState([])
  const [panelList, setpanelList] = React.useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch, setBranch] = React.useState('All')
  const [branches, setBranches] = React.useState([])
  const [state, setState] = React.useState({
    status: 'All',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if (localStorage.getItem('branch') == null || localStorage.getItem('branch') == undefined) {
      localStorage.clear()
      window.location.href = "https://fslabs.com.pk"
    }
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
      setBranches(response)

    })
    if (localStorage.getItem('refresh_portal') == null || localStorage.getItem('refresh_portal') == undefined) {
      localStorage.clear()
      window.location.href = "https://fslabs.com.pk/Login"
    }
    var patient_id = localStorage.getItem('patient_id')
    var role = localStorage.getItem('role')

    var from = new Date();
    if (role == 'Panel') {
      from.setDate(from.getDate() - 1);
    }
    if (role == 'Admin') {
      from.setDate(from.getDate() - 1);
    }
    if (role == 'Patient') {
      from = new Date('1/1/2019')
    }
    var branchID = "0"
    if (localStorage.getItem('branch') == 'false') {
      branchID = "0"
    } else {
      branchID = localStorage.getItem('branchID')
    }
    var to = new Date();
    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
    var vSearchStr = JSON.stringify(SearchFormData);

    var url = "https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole=" + role

    fetch(url).then((res) => res.json())
      .then((result) => {
        var the_rows = []
        result.map((data) => {
          var br_title = ""

          if (data.B_TiTLE == "METROPOLE") {
            br_title = "Islamabad"
          } else {

            br_title = data.B_TiTLE.split('MPL,')[1]


          }
          if (localStorage.getItem('patient_id') == 'AR-01033') {


            if (data.B_TiTLE.toLowerCase().includes('genomic rwp') || data.B_TiTLE.toLowerCase().includes('ddlc') || data.B_TiTLE.toLowerCase().includes('biogene') || data.B_TiTLE.toLowerCase().includes('device solutions') || data.B_TiTLE.toLowerCase().includes('medikay') || data.B_TiTLE.toLowerCase().includes('blue area') || data.B_TiTLE.toLowerCase().includes('mpl-mhc')) {
              br_title = "Islamabad"
            }
            if (data.B_TiTLE.toLowerCase().includes('6th road rwp') || data.B_TiTLE.toLowerCase().includes('falak air') || data.B_TiTLE.toLowerCase().includes('abrar')) {
              br_title = "Rawalpindi"
            }
          }

          var obj = {
            id: data.Invoice_ID,
            name: data.NAME_F + " " + data.NAME_L,
            title: data.T_TiTLE,
            status: data.Test_Status,
            cnic: data.CNIC,
            mobile: data.Mobile_No,
            passport: data.Passport_No,
            reference_no: data.Reference_No,
            code: data.T_CODE,
            Panel_Code: data.Panel_Code,
            Panel_Name: data.Panel_Name,
            branch: br_title,
            dateObject: new Date(data.ACCESS_DATE),
            patient_invoice: <center>
              <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()

                  var obj1 = {
                    branch: data.B_TiTLE,
                    name: data.NAME_F + " " + data.NAME_L,
                    age_gender: data.Age_gender,
                    password: data.Patient_PW,
                    patient_id: data.SYSTEM_ID,
                    invoice_time: msToTime(data.ACCESS_TiME),
                    invoice_date: invoice_date,
                    invoice_id: data.Invoice_ID,
                    contact: data.Mobile_No,
                    Panel_Name: data.Panel_Name,
                    passport_no: data.Passport_No,
                    flight_date: flight_date,
                    flight_no: data.Flight_no,
                    flight_time: msToTime(data.Flight_Time),
                    toAddress: data.To_Address,
                    reports: [
                      {
                        sn: "1", test_title: " COVID-19 RNA BY PCR", reporting_date: data.Rep_TimeDate, fee: "6,500.00", disc: "0.00", net_value: "6,500.00",
                        flight_date: flight_date,
                        flight_no: data.Flight_no,
                        flight_time: msToTime(data.Flight_Time),
                        passport_no: data.Passport_No,
                        destination: data.Destination,
                        cnic: data.CNIC,
                        name: data.NAME_F + " " + data.NAME_L,
                        passport: data.Passport_No,

                        user_id: data.User_ID
                      }
                    ]
                  }
                  setSelected(obj1)
                  setPatientInvoice(true)
                } else {
                  alert("Only for COVID-19 Reports")
                }
              }}>
                <  ReceiptIcon />
              </Button>
            </center>
            ,
            invoice: <center>
              <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetInvoicePDF/' + data.InV_AuX_ID)
              }}>
                <  ReceiptIcon />
              </Button>
            </center>
            ,
            download:<center>
                
                <Button variant="contained" color="secondary" disableElevation onClick={() => {
                        if (data.Test_Status == 'Results Ready') {
                        
                            window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
                            // window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/WebServerReportingFSsoft?AuxID='+data.InV_AuX_ID+'&PatientID='+data.SYSTEM_ID+'&InvoiceNo=' + data.Invoice_ID)
                          
                        } else {
                          alert('Results not Ready!!')
                        }
                      }} >
                        <  PictureAsPdfSharpIcon />
                      </Button>
              </center>
          }
          the_rows.push(obj)

        })

        setRows(the_rows)
        setOrg_Rows(the_rows)
        setLoading(false)
      })
    
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetAllPanelsList").then((res) => res.json())
      .then((result) => {
        setpanelList(result)
      })
  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };

  /// Japn Certificate Function
  // 30 Apr 2021


  return (
    <>
      <Paper className={classes.root}>
        <div style={{ padding: '1em' }}>
          <h3>Status</h3>
          <GridContainer>
            <GridItem xs={3} sm={3} md={3} md={1}>
              <div style={{
                backgroundColor: 'brown', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Just Booked</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'red', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Only Phlebotomy Done</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
              <div style={{
                backgroundColor: 'purple', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Lying Pending Results</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} md={1}>
              <div style={{
                backgroundColor: 'blue', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Done But not Signed</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} md={1}>
              <div style={{
                backgroundColor: 'green', width: "50%",
                height: "50%"
              }}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1.5}>
              <p style={{ color: 'black' }}>Results Ready</p>
            </GridItem>

          </GridContainer>

        </div>
        <GridContainer style={{ padding: '2em' }}>
          <GridItem xs={6} sm={6} md={2} lg={2}>
            <InputLabel className={classes.label}>
              <b>  From </b>
            </InputLabel>



            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker

                variant="inline"
                format="dd/MM/yyyy"
                value={from}
                minDate={temp_role == "Admin" ? new Date().setMonth(new Date().getMonth - 2) : new Date().setFullYear(new Date().getFullYear() - 3)}
                maxDate={to}
                onChange={(date) => {
                  setFrom(date)
                }}

              />
            </MuiPickersUtilsProvider>


          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }

          <GridItem xs={6} sm={6} md={2} lg={2}>

            <InputLabel className={classes.label}>
              <b> To </b>
            </InputLabel>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker

                variant="inline"
                format="dd/MM/yyyy"
                value={to}
                minDate={from}
                maxDate={new Date()}
                onChange={(date) => {
                  setTo(date)
                }}

              />
            </MuiPickersUtilsProvider>
          </GridItem>
          {
            width < 600
              ?
              <GridItem xs={6} sm={6} md={0} lg={0}>

              </GridItem> : ""
          }
          <GridItem xs={6} sm={6} md={2} lg={2}>
            <FormControl variant="filled" className={classes.formControl} fullWidth>
              <InputLabel htmlFor="filled-age-native-simple">Status</InputLabel>
              <Select
                native
                value={state.status}
                onChange={handleChange}
                inputProps={{
                  name: 'status',
                  id: 'filled-age-native-simple',
                }}
              >
                <option aria-label="Select" value="All" >All</option>
                <option value={'Just Booked'}>Just Booked</option>
                <option value={'Only Phlebotomy Done'}>Only Phlebotomy Done</option>
                <option value={'Lying Pending Results'}>Lying Pending Results</option>
                <option value={'Results Done But not Signed'}>Results Done But not Signed</option>
                <option value={'Results Ready'}>Results Ready</option>

              </Select>
            </FormControl>
          </GridItem>
          {
            !localStorage.getItem('patient_name').toLowerCase().includes("mpl-") && localStorage.getItem('role') != "Panel" && localStorage.getItem('role') != "Patient" ?
              <>
              
                <GridItem xs={6} sm={6} md={2} lg={2}>
                  <FormControl variant="filled" className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="filled-age-native-simple">Panel</InputLabel>
                    <Select
                      native
                      value={panel}
                      onChange={(event) => {
                        const value = event.target.value;
                        setPanel(value)
                      }}
                      inputProps={{
                        name: 'status',
                        id: 'filled-age-native-simple',
                      }}
                    >
                      <option aria-label="Select" value="All" >All</option>
                      {
                        panelList.map((p) => {
                          return <option value={p.Panel_Code}>{p.Panel_Name}</option>
                        })
                      }

                    </Select>
                  </FormControl>
                </GridItem>
              </>
              :
              <></>
          }

          <GridItem xs={12} sm={12} md={2} lg={2} style={{ margin: 'auto' }} >

            <Button variant="contained" color="primary" disableElevation disabled={loading}
              onClick={() => {
                if (to == 'Invalid Date' || from == 'Invalid Date' || to == null || from == null) {
                  alert("Invalid Date")
                  return
                }
                var role = localStorage.getItem('role')
                var patient_id = localStorage.getItem('patient_id')
                var branchID = "0"
                setLoading(true)
                if (localStorage.getItem('branch') == 'false') {
                  branchID = "0"
                } else {
                  branchID = localStorage.getItem('branchID')
                }

                var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID: branchID }
                var vSearchStr = JSON.stringify(SearchFormData);

                var url = "https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole=" + role

                fetch(url).then((res) => res.json())
                  .then((result) => {
                    var the_rows = []
                    var name = localStorage.getItem('patient_name')
                    result.map((data) => {
                      var br_title = ""
                      if (data.B_TiTLE == "METROPOLE") {
                        br_title = "Islamabad"
                      } else {

                        br_title = data.B_TiTLE.split('MPL,')[1]


                      }
                      if (localStorage.getItem('patient_id') == 'AR-01033') {
                        if (data.B_TiTLE.toLowerCase().includes('genomic rwp') || data.B_TiTLE.toLowerCase().includes('ddlc') || data.B_TiTLE.toLowerCase().includes('biogene') || data.B_TiTLE.toLowerCase().includes('device solutions') || data.B_TiTLE.toLowerCase().includes('medikay') || data.B_TiTLE.toLowerCase().includes('blue area') || data.B_TiTLE.toLowerCase().includes('mpl-mhc')) {
                          br_title = "Islamabad"
                        }
                        if (data.B_TiTLE.toLowerCase().includes('6th road rwp') || data.B_TiTLE.toLowerCase().includes('falak air') || data.B_TiTLE.toLowerCase().includes('abrar')) {
                          br_title = "Rawalpindi"
                        }
                      }
                      var obj = {
                        id: data.Invoice_ID,
                        name: data.NAME_F + " " + data.NAME_L,
                        title: data.T_TiTLE,
                        status: data.Test_Status,
                        cnic: data.CNIC,
                        mobile: data.Mobile_No,
                        passport: data.Passport_No,
                        reference_no: data.Reference_No,
                        code: data.T_CODE,
                        Panel_Code: data.Panel_Code,
                        Panel_Name: data.Panel_Name,
                        branch: br_title,
                        dateObject: new Date(data.ACCESS_DATE),
                        patient_invoice: <center>
                          <Button variant="contained" style={{ backgroundColor: 'purple', color: 'white' }} key={data.Invoice_ID} disableElevation onClick={() => {
                            if (data.T_TiTLE == "Covid-19 RNA by PCR") {
                              var flight_date = new Date(data.Flight_Date).ddmmyyy()
                              var invoice_date = new Date(data.ACCESS_DATE).ddmmyyy()
            
                              var obj1 = {
                                branch: data.B_TiTLE,
                                name: data.NAME_F + " " + data.NAME_L,
                                age_gender: data.Age_gender,
                                password: data.Patient_PW,
                                patient_id: data.SYSTEM_ID,
                                invoice_time: msToTime(data.ACCESS_TiME),
                                invoice_date: invoice_date,
                                invoice_id: data.Invoice_ID,
                                contact: data.Mobile_No,
                                Panel_Name: data.Panel_Name,
                                passport_no: data.Passport_No,
                                flight_date: flight_date,
                                flight_no: data.Flight_no,
                                flight_time: msToTime(data.Flight_Time),
                                toAddress: data.To_Address,
                                reports: [
                                  {
                                    sn: "1", test_title: " COVID-19 RNA BY PCR", reporting_date: data.Rep_TimeDate, fee: "6,500.00", disc: "0.00", net_value: "6,500.00",
                                    flight_date: flight_date,
                                    flight_no: data.Flight_no,
                                    flight_time: msToTime(data.Flight_Time),
                                    passport_no: data.Passport_No,
                                    destination: data.Destination,
                                    cnic: data.CNIC,
                                    name: data.NAME_F + " " + data.NAME_L,
                                    passport: data.Passport_No,
            
                                    user_id: data.User_ID
                                  }
                                ]
                              }
                              setSelected(obj1)
                              setPatientInvoice(true)
                            } else {
                              alert("Only for COVID-19 Reports")
                            }
                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,
                        invoice: <center>
                          <Button variant="contained" style={{ backgroundColor: 'green', color: 'white' }} disableElevation onClick={() => {
                            window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetInvoicePDF/' + data.InV_AuX_ID)
                          }}>
                            <  ReceiptIcon />
                          </Button>
                        </center>
                        ,
                        download:<center>
                            
                            <Button variant="contained" color="secondary" disableElevation onClick={() => {
                                    if (data.Test_Status == 'Results Ready') {
                                    
                                        window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
                                      
                                        // window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/WebServerReportingFSsoft?AuxID='+data.InV_AuX_ID+'&PatientID='+data.SYSTEM_ID+'&InvoiceNo=' + data.Invoice_ID)
                                    } else {
                                      alert('Results not Ready!!')
                                    }
                                  }} >
                                    <  PictureAsPdfSharpIcon />
                                  </Button>
                          </center>
                      }
                      the_rows.push(obj)


                    })
                    var current_rows = []
                    the_rows.map((data) => {
                      // if (data.branch.toLowerCase().includes(branch.toLowerCase()) || (data.branch == 'METROPOLE' && branch == "Islamabad") || branch == "All") {
                      if ( branch == "All") {
                        if (state.status == "All") {
                          if (panel == "All") {
                            current_rows.push(data)
                          } else {
                            if (data.Panel_Code == panel) {
                              current_rows.push(data)
                            }
                          }

                        } else {
                          if (state.status.trim() == data.status.trim()) {
                            if (panel == "All") {
                              current_rows.push(data)
                            } else {
                              if (data.Panel_Code == panel) {
                                current_rows.push(data)
                              }
                            }
                          }
                        }
                      }


                    })
                    setRows(current_rows)
                    setOrg_Rows(current_rows)
                    setLoading(false)
                    document.getElementById('record_search').value = ""
                    setPage(0)
                  })
              
              }}
            >
              Search<SearchIcon />
            </Button>

          </GridItem>
        </GridContainer>
        <div align="right">
          {localStorage.getItem('role') == 'Admin' ?
            <TextField id="record_search" label="Search by Invoice ID, Passport No , Reference No , CNIC , Patient Name , Mobile No , Test Name and Test Code"
              style={{ maxWidth: '50em' }}
              fullWidth={true}

              onChange={async (e) => {
                var lab_tests = []
                var text = e.target.value
                var data = await org_rows.map((item) => {
                  if (item.title == "Covid-19 RNA by PCR") {
                    if (item.cnic.includes(text)) {
                      lab_tests.push(item)
                      return
                    }
                    if (item.passport.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return
                    }
                    if (item.reference_no.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return
                    }
                  }
                  if (item.mobile.includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return

                  }
                  if (item.mobile.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return

                  }
                  if (item.title.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return

                  }

                  if (item.name.toLowerCase().trim().includes(text.toLowerCase().trim())) {
                    lab_tests.push(item)
                    return

                  }
                  if (item.code.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return

                  }
                  if (item.id.toLowerCase().includes(text.toLowerCase())) {
                    lab_tests.push(item)
                    return

                  }

                  return item

                })
                Promise.all(data).then((item) => {

                  setRows(lab_tests)

                })

              }}
            />
            : <TextField id="record_search" label="Search"

              onChange={async (e) => {
                var lab_tests = []
                var text = e.target.value
                if (page != 0) {
                  setPage(0)
                }


                if (localStorage.getItem('patient_id') == 'AR-01033') {
                  if (text.trim() == "") {
                    return
                  }
                  if (text.length < 3) {
                    setRows(org_rows)
                    return

                  }
                  var data = await SearchRecords.map((item) => {


                    if (item.title.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.name.trim().toLowerCase().includes(text.toLowerCase().trim())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.branch.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.code.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.id.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }

                    return item

                  })
                  Promise.all(data).then((item) => {

                    setRows(lab_tests)

                  })
                } else {
                  var data = await org_rows.map((item) => {


                    if (item.title.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.name.toLowerCase().trim().includes(text.toLowerCase().trim())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.branch.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.code.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }
                    if (item.id.toLowerCase().includes(text.toLowerCase())) {
                      lab_tests.push(item)
                      return

                    }

                    return item

                  })
                  Promise.all(data).then((item) => {

                    setRows(lab_tests)

                  })
                }


              }}
            />}
        </div>
        {loading ?
          <center>
            <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
          </center>
          :
          <>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                        {columns.map((column) => {
                          const value = row[column.id];
                          var status_color = value == 'Just Booked' ? 'brown' : value == 'Only Phlebotomy Done' ? 'red' :
                            value == 'Lying Pending Results' ? 'purple' : value == 'Results Done But not Signed' ? 'blue' : value == 'Results Ready' ? 'green' : ''

                          return (
                            <>
                              {status_color.trim() != '' ?
                                <TableCell key={column.id} align={column.align} style={{ backgroundColor: status_color, color: 'white' }} >
                                  <i><b>{value}</b></i>
                                </TableCell>
                                : <TableCell key={column.id} align={column.align} >
                                  {value}
                                </TableCell>}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        }


      </Paper>
      
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={patient_invoice}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setPatientInvoice(false)
          setSelected({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Passenger Invoice</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selected.name != undefined ?
              <center> <Fragment key={"sqq"}>
                <PDFViewer width="1000" height="1000" className="app" >
                  <Invoice invoice={selected} />
                </PDFViewer>
              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setPatientInvoice(false)
              setSelected({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenCovidTemplate}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setCovidTemplate(false)
          setSelectedCovid({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedCovid.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<CovidTemplate invoice={selectedCovid} />} fileName={selectedCovid.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" size={"large"} color="primary" style={{
                          fontSize: "1.5em",
                          padding: "1em"
                        }}>Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left',

                        fontSize: "1.5em",
                        padding: "1em"
                      }}
                      variant="contained" color={'secondary'} size={"large"} onClick={() => {
                        setCovidTemplate(false)
                        var temp = selectedCovid
                        localStorage.setItem('covid_report', JSON.stringify(temp))
                        setSelectedCovid({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedCovid(JSON.parse(localStorage.getItem('covid_report')))
                          setTimeout(() => {
                            setCovidTemplate(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
       <RefreshIcon size={"large"} style={{
                        fontSize: "1.5em"
                      }} /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <CovidTemplate invoice={selectedCovid} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setCovidTemplate(false)
              setSelectedCovid({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}

        maxWidth={'xl'}
        fullWidth={true}
        open={OpenCovidRAT}
        TransitionComponent={Transition}
        keepMounted

        onClose={() => {
          setOpenCovidRAT(false)
          setSelectedRAT({
            reports: []
          })
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >

          <h4 className={classes.modalTitle}><b>Lab Report</b></h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {
            selectedRAT.name != undefined ?
              <center> <Fragment key={"sqwewq"} >
                <GridContainer>
                  <GridItem md={2}>

                    <PDFDownloadLink
                      style={{
                        float: 'left'
                      }}
                      document={<Covid_AntiGen invoice={selectedRAT} />} fileName={selectedRAT.invoice_id + ".pdf"}>
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' :
                        <Button variant="contained" size={"large"} color="primary" style={{
                          fontSize: "1.5em",
                          padding: "1em"
                        }}>Download Report</Button>)}
                    </PDFDownloadLink>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Button
                      style={{
                        float: 'left',

                        fontSize: "1.5em",
                        padding: "1em"
                      }}
                      variant="contained" color={'secondary'} size={"large"} onClick={() => {
                        setOpenCovidRAT(false)
                        var temp = selectedRAT
                        localStorage.setItem('covid_rat', JSON.stringify(temp))
                        setSelectedRAT({
                          reports: []
                        })
                        //console.log(temp)
                        setTimeout((temp) => {
                          console.log(temp)
                          setSelectedRAT(JSON.parse(localStorage.getItem('covid_rat')))
                          setTimeout(() => {
                            setOpenCovidRAT(true)
                          }, 500);

                        }, 400);
                      }}>
                      Refresh Report
       <RefreshIcon size={"large"} style={{
                        fontSize: "1.5em"
                      }} /></Button>

                  </GridItem>

                  <GridItem md={10}>
                    <PDFViewer width="1000" height="1000" className="app" >
                      <Covid_AntiGen invoice={selectedRAT} />
                    </PDFViewer>
                  </GridItem>
                </GridContainer>

              </Fragment>
              </center>
              : <></>
          }
        </DialogContent>
        <DialogActions className={classes.modalFooter}>

          <Button
            onClick={() => {
              setOpenCovidRAT(false)
              setSelectedRAT({
                reports: []
              })
            }}
            color="danger"
            simple
          >
            Ok
                    </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
