import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';


import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';


import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Molecular(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img_stock/moleculer_dep_2.jpeg")}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>   
      
       
           
              <h1 className={classes.title}>Molecular Biology
</h1>
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em"}} >
       
            <br />
  <GridContainer>
         <GridItem xs={12} sm={12} md={6} lg={6}>
             <h3 className={classes.title} style={{color:'black'}}>Molecular Biology</h3>
             <p   style={{color:'black'}}><span class="fa fa-star checked"></span> Molecular biology department is providing highly accurate diagnosis of infectious diseases and treatment options to clinicians. 
             <br />
             <br />
             <span class="fa fa-star checked"></span>Our molecular biology department is equipped with Cepheid Gene expert molecular system for the detection and viral loads of many viral & Bacterial pathogens including Hepatitis, B , Hepatitis C , HIV &  Mycobacterium Tuberculosis.  
             
             <br />
             <br />
             <span class="fa fa-star checked"></span>Our Gene expert system also provides Drug resistance to Tuberculosis. Moreover we have state of the Art LAMP (Loop mediated Isothermal Amplification) system by Hibergene diagnostics Ireland  for the rapid molecular diagnostics of many infectious diseases including  Meningitis, C.defficile, Mycoplasma Pneumoniae , Flue A/B and a panel of STDs.    
            </p>
             </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
          <img
                src={require("assets/img_stock/moleculer_dep_1.jpeg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer>
      <br />
      
            <FreeHS_Msg />
          {/* <ProductSection />
          <TeamSection />
          <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
