import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import Button from "components/CustomButtons/Button.js";
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';

import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';




function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'Test_Name', numeric: false, disablePadding: true, label: 'Test Name' },
  { id: 'Sample_Type', numeric: false, disablePadding: false, label: 'Sample Type' },
  { id: 'Delivery_Time', numeric: false, disablePadding: false, label: 'Delivery Time' },
  { id: 'Test_Price', numeric: true, disablePadding: false, label: 'Test Price' }
  
];


function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: '#1a5790',
          backgroundColor: lighten('#1a5790', 0.85),
        }
      : {
          color: '#1a5790',
          backgroundColor: '#1a5790',
        },
  title: {
    flex: '1 1 100%',
  },
}));
var selected_LabTests=[]

const EnhancedTableToolbar = (props) => {

  const classes = useToolbarStyles();
  const { numSelected } = props;
const {Add_Tests_to_List }=props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          MPL Test List
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Select">
          <Button
                  color="primary"
                  style={{backgroundColor:'#1a5790'}}
                  onClick={()=>{
                    Add_Tests_to_List()
                  }}
                >
                  Select
                  </Button>
        </Tooltip>
      ) : (
       <></>
        )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default class TestSelection extends React.Component {
    constructor(props){
        super(props)
        this.state={
            order:'asc',
            setOrder:'asc',
            selected:[],
            setSelected:[],
            orderBy:'calories',
            setOrderBy:'calories',
            page:0,
            setPage:0,
            dense:false,
            rows:[],
            org_rows:[],
            setDense:false,
            rowsPerPage:10,
            setRowsPerPage:10,
            
 
        }
        this.Add_Tests_to_List = this.Add_Tests_to_List.bind(this)
    }
    
    componentWillMount(){
        var patient_id = localStorage.getItem('patient_id')
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetPatientSTestPrices?vQuery={%22PIN%22:%22%22,%22PhoneNo%22:%22%22,%22PatientName%22:%22%22,%22RegDateFrom%22:%222000-02-01T04:08:27.995Z%22,%22RegDateTo%22:%222020-08-12T04:08:27.995Z%22}&vUID="+patient_id+"&vRole=Patient"
        ).then((res)=>res.json())
         .then((result)=>{
          this.setState({rows:result})
          this.setState({org_rows:result})
         })

    }
    Add_Tests_to_List(){
      this.props.addTest(selected_LabTests)
      this.props.CloseModal()
    }
    
    emptyRows=()=> this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.state.rows.length - this.state.page * this.state.rowsPerPage)
     handleRequestSort = (event, property) => {
      const isAsc = this.state.orderBy === property && this.state.order === 'asc';
      this.setState({setOrder :(isAsc ? 'desc' : 'asc')});
      this.setState({setOrderBy:property})
    };
  
     handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = this.state.rows.map((n) => n);
        this.setState({selected:newSelecteds})
        selected_LabTests=newSelecteds
        return;
      }
      this.setState({selected:[]})
    };
    classes=()=>useStyles()
  
     handleClick = (event, name) => {
      const selectedIndex = this.state.selected.indexOf(name);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(this.state.selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      } else if (selectedIndex === this.state.selected.length - 1) {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            this.state.selected.slice(0, selectedIndex),
            this.state.selected.slice(selectedIndex + 1),
        );
      }
      selected_LabTests=newSelected
      this.setState({selected:newSelected})
      
    };
  
    handleChangePage = (event, newPage) => {
      this.setState({setPage:newPage})
    };
  
    handleChangeRowsPerPage = (event) => {
        this.setState({setRowsPerPage:(parseInt(event.target.value, 10))});
        this.setState({setPage:0})
    };
  
    handleChangeDense = (event) => {
        this.setState({setDense:event.target.checked});
    };
  
    isSelected = (name) => {
    
        return this.state.selected.indexOf(name) != -1;
    }
  
  render(){
  return (
    <div className={this.classes.root}>
      <Paper className={this.classes.paper}>
          <div align="right">
               <TextField id="standard-basic" label="Search"
               onChange={async (e)=>{
                   var lab_tests=[]
                   var text=e.target.value
                  var data=await this.state.org_rows.map((item)=>{
                      if(item.Test_Name.toLowerCase().includes(text.toLowerCase())){
                          lab_tests.push(item)
                       
                      }
                      return item
              
                  })
                  Promise.all(data).then((item)=>{
                     this.setState({rows:lab_tests})
                  })
                  
            }}              
               />
               </div>
        <EnhancedTableToolbar numSelected={this.state.selected.length} Add_Tests_to_List={this.Add_Tests_to_List} />
        <TableContainer>
          <Table
            className={this.classes.table}
            aria-labelledby="tableTitle"
            size={this.state.dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            
          >
           
            <EnhancedTableHead
              classes={this.classes}
              numSelected={this.state.selected.length}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}

              rowCount={this.state.rows.length}
            />
            <TableBody>
              {stableSort(this.state.rows, getComparator(this.state.order, this.state.orderBy))
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => this.handleClick(event, row)}
                      role="checkbox"
                      aria-checked={this.isSelected(row)}
                      tabIndex={-1}
                      
                      key={row.Test_Name}
                      selected={this.isSelected(row)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={this.isSelected(row)}
                          inputProps={{ 'aria-label-ledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.Test_Name}
                      </TableCell>
                      <TableCell align="left">{row.Sample_Type}</TableCell>
                      <TableCell align="left">{row.Delivery_Time}</TableCell>
                      <TableCell align="right">{row.Test_Price}</TableCell>
                      
                    </TableRow>

                  );
                })}
              
              {this.emptyRows() > 0 && (
                <TableRow style={{ height: (this.state.dense ? 33 : 53) * this.emptyRows() }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.state.rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
      
    </div>
  );
}
}
