import React ,{useEffect,Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import HelpIcon from '@material-ui/icons/Help';

import ReactExport from 'react-data-export';

import {isMobile} from 'react-device-detect';
import TableRow from '@material-ui/core/TableRow';

import Button from '@material-ui/core/Button';
import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';


import Slide from "@material-ui/core/Slide";


import { Page, Text, View, Document, StyleSheet ,PDFDownloadLink} from '@react-pdf/renderer';
import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
var id=localStorage.getItem('patient_name')+""


const columns = 
  [    
{ id: 'id', label: 'User ID' },
  { id: 'name', label: 'User Name' },
  { id: 'covid_cases', label: 'Covid-19 Booking' },
  { id: 'other_cases', label: 'Other Booking' }

]
  

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime (ms) {
  var seconds = (ms/1000);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return hours + ':' + minutes ;
}


export default function Lab_Tests() {
  const classes = useStyles();
  var date=new Date()
  var temp_role=localStorage.getItem('role')
  if(temp_role=='Panel'){
  date.setDate(date.getDate() - 1);
  }
  if(temp_role=='Admin'){
    date.setDate(date.getDate() - 1);
  }

  if(temp_role=='Patient'){
    date=new Date('1/1/2019')
  }
  

  const [selected,setSelected]=React.useState({
    reports:[]
  })
  const [temp_selected,setSelectedTemp]=React.useState({
    reports:[]
  })
  const [selectedCovid,setSelectedCovid]=React.useState({
    reports:[]
  })
  const [OpenCovidTemplate,setCovidTemplate]=React.useState(false)
  const [patient_invoice,setPatientInvoice]=React.useState(false)
  const [from,setFrom] = React.useState(date)
  const [to, setTo] = React.useState(new Date())
  const [width, height] = [window.innerWidth, window.outerWidth];
  const [panel,setPanel]=React.useState('All')
  const [page, setPage] = React.useState(0);
  const [data_export, setData_Export] = React.useState([])
  const [covidButton,setCovidButton]=React.useState(false)
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows]= React.useState([]);
  const [org_rows,setOrg_Rows]= React.useState([]);
  const [loading,setLoading]=React.useState(true)
  const [panelList,setpanelList]=React.useState([])
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch,setBranch]=React.useState('All')
  const [branches,setBranches]=React.useState([])
  const [state, setState] = React.useState({
    status:'All',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const multiDataSet = [
    {
      columns: [
        { title: "ID", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },//char width 
        { title: "User Name", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Covid-19 Booking", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "NON-COVID19 Booking", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
      ],
      data: data_export
    }
  ];
  const getData = async (data) => {
  
  
    var rows_export = []
    var d = date.toISOString()
    var date_ = new Date(d).ddmmyyy()
  
    var res = await data.map((item) => {
      var obj = [
        { value: item.id, width: { wch: 30 },style: { font: { sz: "14" }, alignment: { horizontal: 'center' } } },
        { value: item.name, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.covid_cases, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.other_cases, width: { wch: 30 },style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } }
         ]
      rows_export.push(obj)
  
      return
  
    })
  
    Promise.all(res).then(() => {
      setData_Export(rows_export)
    })
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
  
    var patient_id= localStorage.getItem('patient_id')
    var role=localStorage.getItem('role')
  
  
    var to= new Date();
    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID:"0" }
    var vSearchStr = JSON.stringify(SearchFormData);
  
    var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole="+role
  
    fetch(url).then((res)=>res.json())
     .then((result)=>{
    
      var counter={}
      result.map((data)=>{
        if(data.T_TiTLE!="Covid-19 RNA by PCR"){
          if( counter[data.User_ID]==undefined){
            counter[data.User_ID]={
                id:data.User_ID,
                name:data.User_Name,
                other_cases:1,
                covid_cases:0

            }
   
        }else{
           counter[data.User_ID].other_cases=counter[data.User_ID].other_cases+1
        }
        }else{
          if( counter[data.User_ID]==undefined){
            counter[data.User_ID]={
                id:data.User_ID,
                name:data.User_Name,
                other_cases:0,
                covid_cases:1
            }
   
        }else{
           counter[data.User_ID].covid_cases=counter[data.User_ID].covid_cases+1
        }
        }

       

      })
      var entires=[]
      Object.keys(counter).map((key)=>{
          entires.push(counter[key])
      })
      setRows(entires)
      setOrg_Rows(entires)
      setLoading(false)
      getData(entires)
      setPage(0)
     })
    
  },[])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes(); 
    var hh = this.getHours();

    return [
        (hh > 9 ? '' : '0') + hh,
        (mm > 9 ? '' : '0') + mm
    ].join(':');
};

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
  return (
    <>
    <Paper className={classes.root}>
   
      <GridContainer style={{padding:'2em'}}>
                <GridItem xs={6} sm={6} md={2} lg={2}>
                  <InputLabel className={classes.label}>
                  <b>  From </b>
                  </InputLabel>
                  
                  <FormControl >
                    <Datetime
                      inputProps={{ placeholder: "From Date Here" }}
                      value={from}
                      timeFormat=""
                      onChange={(date)=>{
                        setFrom(date)
                      }}
                      dateFormat='DD-MM-YYYY'
                    />
                  </FormControl>
                </GridItem>
                {
                  width<600
                  ?
<GridItem xs={6} sm={6} md={0} lg={0}>

</GridItem>:""
                }
                
                <GridItem xs={6} sm={6} md={2} lg={2}>

                  <InputLabel className={classes.label}>
                   <b> To </b>
                  </InputLabel>
                 
                    <Datetime
                    style={{width:'1em'}}
                      inputProps={{ placeholder: "To Date Here" }}
                      value={to}
                      
                      onChange={(date)=>{
                        setTo(date)
                      }}
                      timeFormat=""
                      dateFormat='DD-MM-YYYY'
                    />
                  
                </GridItem>
               
                
                 
            
                  <GridItem xs={12} sm={12} md={2} lg={2} style={{margin:'auto'}} >
                    
                  <Button variant="contained" color="primary" disableElevation disabled={loading}
                  onClick={()=>{
                    var role=localStorage.getItem('role')
                    var patient_id= localStorage.getItem('patient_id')
                    var branchID="0"
                    setLoading(true)
                    var SearchFormData = { PIN: "", PhoneNo: "", PatientName: "", RegDateFrom: from.toISOString(), RegDateTo: to.toISOString(), vBranchID:branchID }
                    var vSearchStr = JSON.stringify(SearchFormData);
                  
                    var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestListwithStatus?vQuery=" + vSearchStr + "&vUID=" + patient_id + "&vRole="+role
                  
                    fetch(url).then((res)=>res.json())
                    .then((result)=>{
                   
                     var counter={}
                     result.map((data)=>{
                      if(data.T_TiTLE!="Covid-19 RNA by PCR"){
                        if( counter[data.User_ID]==undefined){
                          counter[data.User_ID]={
                              id:data.User_ID,
                              name:data.User_Name,
                              other_cases:1,
                              covid_cases:0
              
                          }
                 
                      }else{
                         counter[data.User_ID].other_cases=counter[data.User_ID].other_cases+1
                      }
                      }else{
                        if( counter[data.User_ID]==undefined){
                          counter[data.User_ID]={
                              id:data.User_ID,
                              name:data.User_Name,
                              other_cases:0,
                              covid_cases:1
                          }
                 
                      }else{
                         counter[data.User_ID].covid_cases=counter[data.User_ID].covid_cases+1
                      }
                      }
                      
               
                     })
                     
                     var entires=[]
                     Object.keys(counter).map((key)=>{
                         entires.push(counter[key])
                     })
                     setRows(entires)
                     setOrg_Rows(entires)
                     getData(entires)
                     setLoading(false)
                     setPage(0)
                    })

                    
                  }}
                  >
                    Seach<SearchIcon />
                  </Button>
         
                  </GridItem>
                  <GridItem md={3} lg={3} style={{ margin: 'auto', float: 'right' }} >
          <ExcelFile filename="Booking Summary" element={<Button
            color="primary"
            style={{ backgroundColor: 'green', color: "white" }}

          >
            Export Excel
              </Button>}>
            <ExcelSheet dataSet={multiDataSet} name="Booking Summary" />
          </ExcelFile>

        </GridItem>
              </GridContainer>
       
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
            return (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.code+Math.random()} >
                {columns.map((column) => {
                  const value = row[column.id];
                
                  return (
                   <TableCell key={column.id} align={column.align} >
                      {value}
                                       </TableCell>
                 
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
  
      
    </Paper>
   
   
  </>
  );
}
