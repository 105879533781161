import React, { useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import CountUp  from 'react-countup';
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CardActionArea from '@material-ui/core/CardActionArea';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';

import Quote from "components/Typography/Quote.js";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AppleIcon from '@material-ui/icons/Apple';
import LanguageIcon from '@material-ui/icons/Language';

import image from "assets/img/faces/avatar.jpg";

import virus_1 from "assets/img/animations/virus (8).png";
import virus_2 from "assets/img/animations/virus (11).png";
import googleplay from 'assets/img/google_play.png'
import biker from "assets/img/biker.png";
import url from '../../link'
import apple_store from 'assets/img/apple_store.png'
import Counter from './Counter'
import styles from "assets/jss/material-kit-react/views/componentsSections/typographyStyle.js";

const useStyles = makeStyles(styles);


export default function FreeHS_Msg() {
  const classes = useStyles();
  const [visiter,setVisiters]=React.useState(0)
  const [width, height] = [window.innerWidth, window.outerWidth];
  // useEffect(()=>{
  //   fetch("https://api.countapi.xyz/hit/fslabs.com.pk/visits").then((res)=>{return res.json()}).then((response)=>{
  //     var vis=response.value+1088
  //     setVisiters(vis)
  //   })
  // },[])
  return (
    < >
{/*    
   <GridContainer>
      <GridItem xs={3} sm={3} lg={3} md={3}>
        </GridItem>
        <GridItem xs={6} sm={6} lg={6} md={6}>    
        <section id="counter-stats" class="wow fadeInRight" data-wow-duration="1.4s" style={{color:"black"}}>
        <div class="container">
            <div class="row">
    
                
    
                <div class="col-lg-3 stats">
                <div class="wrap">
    <div class="stepper" style={{backgroundColor:'#1a5790'}}>
        <span class="active " > <CountUp delay={2} end={visiter}  /></span>
        <br />
        <div ><span class="active " > VISITORS</span></div>
        
    </div>
   
    </div>
    
                
                </div>
    
                
    
    
            </div>
        
        </div>
    </section>
    </GridItem>
    <GridItem xs={3} sm={3} lg={3} md={3}>
        </GridItem>
    </GridContainer>
    <div className={classes.section}>
      <div className={classes.container}>
      
        <div id="images">
          <GridContainer>
           
            <GridItem xs={2} sm={2} md={2} lg={2} className={classes.marginLeft}>
           
       
              <img
                src={virus_1}
                alt="..."

                className={classes.imgFluid+" "+"funfact-one__virus-1"}
              />
            </GridItem>
            <GridItem xs={8} sm={8} md={8} lg={8}  className="FreeHS">
            <div className={classes.typo} className="FreeHS">
            {width <600 ?
             
            <h4 className={classes.title}>Free Home Sampling</h4>
            :
            <h1 className={classes.title}>Free Home Sampling</h1>
              
          }
             
             <img
                src={biker}
                alt="..."
                style={{width:"5em"}}
              />
              <h4 className={classes.title}>Book Free Home Sampling Via Android App & IOS App</h4>
            
            </div>
              
            </GridItem>
            <GridItem xs={2} sm={2} md={2} lg={2} className={classes.marginLeft}>
              <img
                src={virus_2}
                alt="..."
                className={classes.imgFluid+" funfact-one__virus-2"}
             
              />
            </GridItem>
          </GridContainer>
          <GridContainer  style={{textAlign:'center'}}>
          <GridItem xs={2} sm={2} md={2} lg={2}>
            </GridItem>
        
               <GridItem xs={12} sm={12} md={4} lg={4}>
               
                   <img
                src={googleplay}
                alt="..."
                onClick={()=>{
                  window.open('http://fslabs.com.pk/android')
                }}
                className={classes.imgFluid}
             
              />
               </GridItem>
               <GridItem xs={12} sm={12} md={4} lg={4}>
               <img
                src={apple_store}
                alt="..."
                onClick={()=>{
                  window.open('http://fslabs.com.pk/ios')
                }}
                className={classes.imgFluid}
             
              />
                 </GridItem>
               <GridItem xs={4} sm={4} md={2} lg={2}>
            </GridItem>
         
 
              </GridContainer>
        </div>
      </div>
    </div> */}
    </>
  );
}
