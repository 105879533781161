import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';


import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';


import report from 'assets/img/lab_test.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function Histopathology(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/histopathology_1.jpeg")}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>   
      
       
           
              <h1 className={classes.title}>Histopathology</h1>
              <br />
              {/* <Button
                color="danger"
                size="lg"
                href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ref=creativetim"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fas fa-play" />
                Watch video
              </Button> */}
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em"}} >
       
            <br />
  <GridContainer>
         <GridItem xs={12} sm={12} md={6} lg={6}>
             <h3 className={classes.title} style={{color:'black'}}>Histopathology</h3>
             <p   style={{color:'black'}}><span class="fa fa-star checked"></span> Our panel of highly qualified histopathologists are helping the clinicians & surgeons by making tissue diagnosis for better patient care and manageability. 
             <br />
             <br />
             <span class="fa fa-star checked"></span> Our histo department is fully equipped with routine tissue processing and advance immunohistochemical processors for better diagnosis of tissue samples.  </p>
            
             </GridItem>
          <GridItem xs={12} sm={12} md={6} lg={6}>
          <img
                src={require("assets/img/histopathology_2.jpeg")}
                style={{ 
                maxWidth: "100%",
                height: "auto",
                borderRadius: "calc(.25rem - 1px)",
                boxShadow:
                "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
            
            }}
              />
          </GridItem>
      </GridContainer>
      <br />
      
            <FreeHS_Msg />
          {/* <ProductSection />
          <TeamSection />
          <WorkSection /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
