import React, { useEffect } from 'react';

import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import DateFnsUtils from '@date-io/date-fns';

import * as firebase from 'firebase';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DatePicker from "react-datepicker";
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';


import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import ReactExport from 'react-data-export';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const columns = [
  { id: 'srno', label: 'Sr No' },
  { id: 'patient_id', label: 'Mr#' },
  { id: 'invoice_id', label: 'Lab#' },
  { id: 'name', label: 'Patient Name' },
  { id: 'mobile', label: 'Mobile' },
  { id: 'booking_date_time', label: 'Booking Date/Time' },
  { id: 'result_date_time', label: 'Result Date' },
  { id: 'download', label: 'View Report' },
  { id: 'reference_no', label: 'Booking Ref PNR' },
  { id: 'flight_no', label: 'Flight#' },
  { id: 'flight_date', label: 'Flight Date' },
  { id: 'destination', label: 'Destination' },
  { id: 'passport', label: 'Passport' },
  { id: 'result', label: 'Result' },

];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}


export default function Reports() {
  const classes = useStyles();
  const [to,setTo]=React.useState(new Date())
  const [from,setFrom]=React.useState(new Date())
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [branchDetail, setBranchDetail] = React.useState([]);
  const [data_export, setData_Export] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const [cases,setCases]=React.useState({
    KSA:0,
    AirBlue:0
  })
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch, setBranch] = React.useState('All')
  const [state, setState] = React.useState({
    status: 'All',
  });


  const multiDataSet = [
    {
      columns: [
        { title: "S.No", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true, color: "87CEEB", underline: true } } },//pixels width 
        { title: "Mr#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },//char width 
        { title: "Lab#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Patient Name", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Mobile", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Booking Date/Time", width: { wch: 40 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Result Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Booking Ref PNR", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight Date", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Destination", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Passport", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Result", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },

      ],
      data: data_export
    }
  ];

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  const getData = async (data) => {


    var rows_export = []

    var res = await data.map((item) => {
      var obj = [
        { value: item.srno, style: { font: { sz: "14" }, alignment: { horizontal: 'center' } } },
        { value: item.patient_id, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.invoice_id, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.name, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.mobile, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.booking_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.reference_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_date, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.destination, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.passport, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.result, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } },

      ]
      rows_export.push(obj)

      return

    })

    Promise.all(res).then(() => {
      setData_Export(rows_export)
    })
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    if (firebase.apps.length === 0) {
    

      fetch('https://eviewer.fslabs.com.pk:5443/4DACTION/WebLoginAuthorizeAirBlue',{
      method: 'POST',
      'Content-Type':"application/json",
      body:JSON.stringify({
        vUID:"airblue@mpl"
      })
    }).then((res)=>res.json()).then( async(res)=>{
     
      if (firebase.apps.length === 0) {
        firebase.initializeApp(res[0]);
        fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BranchesLoadAllfromWebserver"
        ).then((res) => res.json())
          .then((result) => {
            setBranchDetail(result)
            fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestsOnlyAirBlue?vQuery={%22RegDateFrom%22:%22" + to.toISOString() + "%22,%22RegDateTo%22:%22" + from.toISOString() + "%22,%22vBranchID%22:%220%22}"
            ).then((res) => res.json())
              .then((result) => {
               var the_rows = []
                var srno = 1
    
                var filter_date=new Date(to.toISOString())
                var month=("0"+(filter_date.getMonth()+1)).slice(-2)

                var day=("0"+(filter_date.getDate())).slice(-2)
                var selected_date=filter_date.getFullYear()+"-"+month+"-"+day
                var temp_cases={
                  KSA:0,
                  AirBlue:0
                 }
                firebase.database().ref("Airlines/AirBlue/"+selected_date).once('value', async (response)=>{
                  if(response.toJSON()==null){
                
                    result.map((data) => {
                      var res_date = new Date(data.Result_Date).ddmmyyy()
                      var flight_date = new Date(data.Flight_Date).ddmmyyy()
                      var test_res = data.Test_Result
        
                      if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                        flight_date = ""
                      }
                      if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                        res_date = ""
                      }
                      if (data.Test_Result == "Covid19 NOT Detected") {
                        test_res = 'negative'
                      }
                      if (data.Test_Result == "Covid19 Detected") {
                        test_res = 'positive'
                      }
                      var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
                      var booking_time = msToTime(data.ACCESS_TiME)
                      if (test_res == "") {
                       
                      }
                  
                      if(data.Panel_Name=="AirBlue"){
                        temp_cases.AirBlue=temp_cases.AirBlue+1
                      }
                      if(data.Panel_Name=="COVID-19 PCR for KSA"){
                        temp_cases.KSA=temp_cases.KSA+1
                      }
        
                      var flight_date = new Date(data.Flight_Date).ddmmyyy()
                      var flight_time = msToTime(data.Flight_Time)
        
                      var obj = {
                        srno: srno,
                        patient_id: data.SYSTEM_ID,
                        invoice_id: data.Invoice_ID,
                        name: data.NAME_F + " " + data.NAME_L,
                        mobile: data.Mobile_No,
                        booking_date_time: booking_date + " " + booking_time,
                        result_date_time: res_date,
                        reference_no: data.Ticket_no,
                        flight_no: data.Flight_no,
                        flight_date: flight_date,
                        flight_time: flight_time,
                        destination: data.Destination,
                        passport: data.Passport_No,
                        result: test_res,
                        download: <center>
                          <Button variant="contained" color="secondary" disableElevation onClick={() => {
                            if (data.Test_Status == 'Results Ready') {
                              window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
        
                            } else {
                              alert('Results not Ready!!')
                            }
                          }} >
                            <  PictureAsPdfSharpIcon />
                          </Button>
                        </center>
                      }
                      srno = srno + 1
                      the_rows.push(obj)
        
                    })
    
                    setRows(the_rows)
                    setOrg_Rows(the_rows)
                    setLoading(false)
                    getData(the_rows)
                    setCases(temp_cases)
                  }else{
                    var arr_string=response.toJSON()
                    var arr=JSON.parse(arr_string)
                    result.map((data) => {
                      if(!arr.includes(data.Invoice_ID)){
                        
                         var res_date = new Date(data.Result_Date).ddmmyyy()
                      var flight_date = new Date(data.Flight_Date).ddmmyyy()
                      var test_res = data.Test_Result
        
                      if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                        flight_date = ""
                      }
                      if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                        res_date = ""
                      }
                      if (data.Test_Result == "Covid19 NOT Detected") {
                        test_res = 'negative'
                      }
                      if (data.Test_Result == "Covid19 Detected") {
                        test_res = 'positive'
                      }
                      var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
                      var booking_time = msToTime(data.ACCESS_TiME)
                      if (test_res == "") {
                        test_res = "Results not Ready Yet"
                      }
                   
                        if(data.Panel_Name=="AirBlue"){
                          temp_cases.AirBlue=temp_cases.AirBlue+1
                        }
                        if(data.Panel_Name=="COVID-19 PCR for KSA"){
                          temp_cases.KSA=temp_cases.KSA+1
                        }
        
                      var flight_date = new Date(data.Flight_Date).ddmmyyy()
                      var flight_time = msToTime(data.Flight_Time)
        
                      var obj = {
                        srno: srno,
                        patient_id: data.SYSTEM_ID,
                        invoice_id: data.Invoice_ID,
                        name: data.NAME_F + " " + data.NAME_L,
                        mobile: data.Mobile_No,
                        booking_date_time: booking_date + " " + booking_time,
                        result_date_time: res_date,
                        reference_no: data.Ticket_no,
                        flight_no: data.Flight_no,
                        flight_date: flight_date,
                        flight_time: flight_time,
                        destination: data.Destination,
                        passport: data.Passport_No,
                        result: test_res,
                        download: <center>
                          <Button variant="contained" color="secondary" disableElevation onClick={() => {
                            if (data.Test_Status == 'Results Ready') {
                              window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
        
                            } else {
                              alert('Results not Ready!!')
                            }
                          }} >
                            <  PictureAsPdfSharpIcon />
                          </Button>
                        </center>
                      }
                      srno = srno + 1
                      the_rows.push(obj)
                      }
                    
        
                    })
    
                    setRows(the_rows)
                      setOrg_Rows(the_rows)
                      setLoading(false)
                      getData(the_rows)
                      setCases(temp_cases)
                  }
    
    
              })
 
              })
          })
    }
   
  })
}
   
  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <Paper className={classes.root}>

      <GridContainer style={{ padding: '2em' }}>
        <GridItem xs={6} sm={6} md={3} lg={3}>
          <InputLabel className={classes.label}>
            <b>  From</b>
          </InputLabel>

          <FormControl fullWidth>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
         
          variant="inline"
          format="dd/MM/yyyy"
          value={to}
          minDate={new Date('12/16/2020')}
          maxDate={from}
          onChange={(date)=>{
         
            if(date< new Date('12/16/2020') || date>from){
              setTo(new Date())
                          }else{
              setTo(date)
                          }

          }}
      
        />
         </MuiPickersUtilsProvider>
          
          </FormControl>
        </GridItem>
        <GridItem xs={6} sm={6} md={3} lg={3}>
        <InputLabel className={classes.label}>
            <b>  TO</b>
          </InputLabel>

          <FormControl fullWidth>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
         
          variant="inline"
          format="dd/MM/yyyy"
          value={from}
          maxDate={new Date()}
          minDate={to}
          onChange={(date)=>{
         
            if(date> new Date() || date<to){

              setFrom(new Date())
            
            }else{
              
              setFrom(date)
                  
            }

          }}
      
        />
         </MuiPickersUtilsProvider>
          
          </FormControl>
          </GridItem>

        <GridItem xs={6} sm={6} md={2} lg={2} style={{ margin: 'auto' }} >

          <Button variant="contained" color="primary" disabled={loading}
            onClick={() => {
              if(to=='Invalid Date' || from=='Invalid Date' || to==null || from ==null){
                alert("Invalid Date")
                return
              }
              setLoading(true)
              fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestsOnlyAirBlue?vQuery={%22RegDateFrom%22:%22" + to.toISOString() + "%22,%22RegDateTo%22:%22" + from.toISOString() + "%22,%22vBranchID%22:%22" + branch + "%22}"
              ).then((res) => res.json())
                .then(async(result) => {
                  
const diffTime = Math.abs(from- to);
const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
var the_rows = []
var srno = 1
if(diffDays==0){
 
  var filter_date=new Date(to.toISOString())
  var month=("0"+(filter_date.getMonth()+1)).slice(-2)
  var temp_cases={
    KSA:0,
    AirBlue:0
   }
  var day=("0"+(filter_date.getDate())).slice(-2)
  var selected_date=filter_date.getFullYear()+"-"+month+"-"+day
            firebase.database().ref("Airlines/AirBlue/"+selected_date).once('value', async (response)=>{
              if(response.toJSON()==null){
             
                result.map((data) => {
                  var res_date = new Date(data.Result_Date).ddmmyyy()
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var test_res = data.Test_Result
    
                  if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                    flight_date = ""
                  }
                  if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                    res_date = ""
                  }
                  if (data.Test_Result == "Covid19 NOT Detected") {
                    test_res = 'negative'
                  }
                  if (data.Test_Result == "Covid19 Detected") {
                    test_res = 'positive'
                  }
                  var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
                  var booking_time = msToTime(data.ACCESS_TiME)
                  if (test_res == "") {
                    test_res = "Results not Ready Yet"
                  }
                  
                  if(data.Panel_Name=="Air MPL"){
                    temp_cases.AirMPL=temp_cases.AirMPL+1
                    }
                    if(data.Panel_Name=="AirBlue"){
                      temp_cases.AirBlue=temp_cases.AirBlue+1
                    }
                    if(data.Panel_Name=="COVID-19 PCR for KSA"){
                      temp_cases.KSA=temp_cases.KSA+1
                    }
                
    
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var flight_time = msToTime(data.Flight_Time)
    
                  var obj = {
                    srno: srno,
                    patient_id: data.SYSTEM_ID,
                    invoice_id: data.Invoice_ID,
                    name: data.NAME_F + " " + data.NAME_L,
                    mobile: data.Mobile_No,
                    booking_date_time: booking_date + " " + booking_time,
                    result_date_time: res_date,
                    reference_no: data.Ticket_no,
                    flight_no: data.Flight_no,
                    flight_date: flight_date,
                    flight_time: flight_time,
                    destination: data.Destination,
                    passport: data.Passport_No,
                    result: test_res,
                    download: <center>
                      <Button variant="contained" color="secondary" disableElevation onClick={() => {
                        if (data.Test_Status == 'Results Ready') {
                          window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
    
                        } else {
                          alert('Results not Ready!!')
                        }
                      }} >
                        <  PictureAsPdfSharpIcon />
                      </Button>
                    </center>
                  }
                  srno = srno + 1
                  the_rows.push(obj)
    
                })

                setRows(the_rows)
                setOrg_Rows(the_rows)
                setLoading(false)
                setCases(temp_cases)
                getData(the_rows)
              }else{
                var arr_string=response.toJSON()
                var arr=JSON.parse(arr_string)
                result.map((data) => {
                  if(!arr.includes(data.Invoice_ID)){
                    
                     var res_date = new Date(data.Result_Date).ddmmyyy()
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var test_res = data.Test_Result
    
                  if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
                    flight_date = ""
                  }
                  if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
                    res_date = ""
                  }
                  if (data.Test_Result == "Covid19 NOT Detected") {
                    test_res = 'negative'
                  }
                  if (data.Test_Result == "Covid19 Detected") {
                    test_res = 'positive'
                  }
                  var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
                  var booking_time = msToTime(data.ACCESS_TiME)
                  if (test_res == "") {
                    test_res = "Results not Ready Yet"
                  }
            
                    if(data.Panel_Name=="AirBlue"){
                      temp_cases.AirBlue=temp_cases.AirBlue+1
                    }
                    if(data.Panel_Name=="COVID-19 PCR for KSA"){
                      temp_cases.KSA=temp_cases.KSA+1
                    }
                  var flight_date = new Date(data.Flight_Date).ddmmyyy()
                  var flight_time = msToTime(data.Flight_Time)
                
    
                  var obj = {
                    srno: srno,
                    patient_id: data.SYSTEM_ID,
                    invoice_id: data.Invoice_ID,
                    name: data.NAME_F + " " + data.NAME_L,
                    mobile: data.Mobile_No,
                    booking_date_time: booking_date + " " + booking_time,
                    result_date_time: res_date,
                    reference_no: data.Ticket_no,
                    flight_no: data.Flight_no,
                    flight_date: flight_date,
                    flight_time: flight_time,
                    destination: data.Destination,
                    passport: data.Passport_No,
                    result: test_res,
                    download: <center>
                      <Button variant="contained" color="secondary" disableElevation onClick={() => {
                        if (data.Test_Status == 'Results Ready') {
                          window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)
    
                        } else {
                          alert('Results not Ready!!')
                        }
                      }} >
                        <  PictureAsPdfSharpIcon />
                      </Button>
                    </center>
                  }
                  srno = srno + 1
                  the_rows.push(obj)
                  }else{
                    temp_cases.Skipped=temp_cases.Skipped+1
                  }
                
    
                })

                setRows(the_rows)
                  setOrg_Rows(the_rows)
                  setLoading(false)
                  getData(the_rows)
                  setCases(temp_cases)
              }


          })
}else{
  let currentDate = new Date(to.toISOString());
  var dates=[]
  while (currentDate <= from) {
   
  var month=("0"+(currentDate.getMonth()+1)).slice(-2)

  var day=("0"+(currentDate.getDate())).slice(-2)
  var selected_date=currentDate.getFullYear()+"-"+month+"-"+day

           
    dates.push(selected_date)  
    currentDate.setUTCDate(currentDate.getUTCDate() +1);
  }
  var highlight_invoices=[]
 var temp= await dates.map((selected_date)=>{
  return firebase.database().ref("Airlines/AirBlue/"+selected_date).once('value',(records)=>{
    var str=records.toJSON()
    if(str!=null){
   var invoices=JSON.parse(str)
   highlight_invoices.push(...invoices)
   return ""
    }
})
 })
 var temp_cases={
  KSA:0,
  AirMPL:0,
  AirBlue:0,
  Skipped:0
 }
Promise.all(temp).then(()=>{
  
  result.map((data) => {
    if(!highlight_invoices.includes(data.Invoice_ID)){
      
       var res_date = new Date(data.Result_Date).ddmmyyy()
    var flight_date = new Date(data.Flight_Date).ddmmyyy()
    var test_res = data.Test_Result

    if (data.Flight_Date == "0000-00-00T00:00:00.000Z") {
      flight_date = ""
    }
    if (data.Result_Date == "0000-00-00T00:00:00.000Z") {
      res_date = ""
    }
    if (data.Test_Result == "Covid19 NOT Detected") {
      test_res = 'negative'
    }
    if (data.Test_Result == "Covid19 Detected") {
      test_res = 'positive'
    }
    var booking_date = new Date(data.ACCESS_DATE).ddmmyyy()
    var booking_time = msToTime(data.ACCESS_TiME)
    if (test_res == "") {
      test_res = "Results not Ready Yet"
    }
    
      if(data.Panel_Name=="AirBlue"){
        temp_cases.AirBlue=temp_cases.AirBlue+1
      }
      if(data.Panel_Name=="COVID-19 PCR for KSA"){
        temp_cases.KSA=temp_cases.KSA+1
      }

    var flight_date = new Date(data.Flight_Date).ddmmyyy()
    var flight_time = msToTime(data.Flight_Time)

    var obj = {
      srno: srno,
      patient_id: data.SYSTEM_ID,
      invoice_id: data.Invoice_ID,
      name: data.NAME_F + " " + data.NAME_L,
      mobile: data.Mobile_No,
      booking_date_time: booking_date + " " + booking_time,
      result_date_time: res_date,
      reference_no: data.Ticket_no,
      flight_no: data.Flight_no,
      flight_date: flight_date,
      flight_time: flight_time,
      destination: data.Destination,
      passport: data.Passport_No,
      result: test_res,
      download: <center>
        <Button variant="contained" color="secondary" disableElevation onClick={() => {
          if (data.Test_Status == 'Results Ready') {
            window.open('https://eviewer.fslabs.com.pk:5443/4DACTION/GetReportPDF/' + data.InV_AuX_ID)

          } else {
            alert('Results not Ready!!')
          }
        }} >
          <  PictureAsPdfSharpIcon />
        </Button>
      </center>
    }
    srno = srno + 1
    the_rows.push(obj)
    }

  })

  setRows(the_rows)
    setOrg_Rows(the_rows)
    setLoading(false)
    getData(the_rows)
    setCases(temp_cases)
})

}
          
          
 
                })
            }}

          >
            Seach<SearchIcon />
          </Button>

        </GridItem>
      
        <GridItem md={3} lg={3} style={{ margin: 'auto', float: 'right' }} >
          <ExcelFile filename="COVID-19 by PCR" element={<Button
            color="primary"
            style={{ backgroundColor: 'green', color: "white" }}

          >
            Export Excel
              </Button>}>
            <ExcelSheet dataSet={multiDataSet} name="COVID-19 by PCR" />
          </ExcelFile>

        </GridItem>
      </GridContainer>
      <GridContainer>
      <GridItem md={1} lg={1}>
         
         </GridItem>
      <GridItem md={3} lg={3}>
          <b style={{color:'red'}}>NON-KSA</b> : <i>{cases.AirBlue}</i>
        </GridItem>
        <GridItem md={3} lg={3}>
          <b style={{color:'red'}}>KSA</b> : <i>{cases.KSA}</i>
        </GridItem>
        <GridItem md={3} lg={3}>
        <b style={{color:'red'}}>Total Cases</b> : <i>{cases.KSA+cases.AirBlue}</i>
        </GridItem>
        <GridItem md={1} lg={1}>
         
         </GridItem>
      </GridContainer>

      {loading ?
        <center>
          <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
        </center>
        :
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (

                          <TableCell key={column.id} align={column.align} >
                            {value}
                          </TableCell>

                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      }
    </Paper>
  );
}

