import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.9.0";

// pages for this product
import Quotation from 'views/Quotation/Quotation.js'
import Components from "views/Components/Components.js";
import FindATest from "views/LandingPage/FindATest.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import LoginPage from "views/LoginPage/LoginPage.js";
import RegisterPage from "views/RegistrationPage/Register.js";
import RecallPassword from "views/RecallPassword/RecallPassword";
import Booking_History from 'views/History/Booking_History'
import Clinic_Chem from 'views/Departments/clinic_chem'
import Immunology from 'views/Departments/Immunology'
import Hematology from 'views/Departments/Hematology'
import Special_Pathology from 'views/Departments/Special_Pathology'
import Covid from 'views/Departments/Covid'
import OurMission from 'views/AboutUs/OurMission'

import Microbiology from 'views/Departments/Microbiology'

import Histopathology from 'views/Departments/Histopathology'
import Imaging from 'views/Departments/Imaging'

import HomeSampling from "views/HomeSampling/HomeSampling";
import Molecular from 'views/Departments/Molecular'
import Lab_Reports from 'views/Lab_Reports/Lab_Reports';
import Molecular_Genetics from 'views/Departments/MolecularGenetics'
import ResetPassword from 'views/ResetPassword/ResetPassword'
import WhoWeAre from 'views/AboutUs/WhoWeAre.js'
import CovidReports from 'views/KSA_Reports/CovidReports'
import Emirates from 'views/Emirates/EmiratesLogin'
import Summary from 'views/Emirates_KSA_Summary/Summary'
import Fast_Track_Registration from "views/Fast_Track_Reg/Fast_Track_Registration";
import COVID19_HomeSampling from 'views/Covid19_HomeSampling/HomeSampling'
import View_Sample_Tracking from 'views/View_Sample_Tracking/View_Sample_Tracking';
import Update_Sample_Tracking from 'views/Update_Sampling_Record/Update_Sampling_Record'
import Sample_Tracking from 'views/sample_tracking/sample_tracking'
import UpdateArhamSerivces from 'views/UpdateArhamServices/UpdateArhamServices'
import SamplingHistory from 'views/History/Booking_History'
import FlyDubai from 'views/FlyDubai/flydubai'
import FlyDubaiReports from 'views/FlyDubaiReports/Summary'
import Overall_Covid_Reports from 'views/Overall_Covid_Reports/CovidReports'
import AirBlue from 'views/AirBlue/AirBlue'
import AirBlueSummary from 'views/AirBlue_Summary/Summary'
import FlyDubaiExcelExport from 'views/FlyDubaiExcelExport/Summary'
import QatarLogin from 'views/QatarLogin/Login'
import SereneAir from 'views/SereneAir/SereneAir'
import SereneAir_Summary from 'views/SereneAir_Summary/Summary'

import OmanAir from 'views/OmanAir/OmanAir'
import OmanAir_Summary from 'views/OmanAir_Summary/Summary'
import QatarSummary from 'views/Qatar_Summary/Summary'
import QatarExcelExport from 'views/QatarExcelExport/Summary'
import Bookings from 'views/BookingCounter/Bookings'

import Onix_Enterprises from 'views/Onix_Enterprise/Onix_Enterprise'

import Onix_Summary from 'views/Onix_Summary/Summary'
import GerrySummary from 'views/GerrysExcelExport/Summary'
import ExportKSACases from 'views/ExportKSACases/Summary'
import EtihadLogin from 'views/EtihadAirwaysLogin/Login'
import Etihad_Summary from 'views/Etihad_Airways/Summary'

import Gerrys_UTF_Service from 'views/Gerrys_UTF_Service/Summary'

import GerryOnlyExcelExport from 'views/GerrysOnlyExcelExport/Summary'

// Travelers Details
import Travelers from 'views/ExportTravelers/Summary'

var hist = createBrowserHistory();

ReactDOM.render(
  <BrowserRouter history={hist}>
    <Switch>
      <Route exact path="/" component={Components} />
      <Route path="/FindTest" component={FindATest} />
      <Route path="/GerryOnlyExcelExport" component={GerryOnlyExcelExport} />

      <Route path="/profile-page" component={ProfilePage} />
      <Route path="/HomeSampling" component={HomeSampling} />
      <Route path="/COVID19_HomeSampling" component={COVID19_HomeSampling} />
      <Route path="/Booking_History" component={Booking_History} />
      <Route path="/Overall_Covid_Reports" component={Overall_Covid_Reports} />
      <Route path="/ResetPassword" component={ResetPassword} />
      <Route path="/Clinic_Chem" component={Clinic_Chem} />
      <Route path="/Immunology" component={Immunology} />
      <Route path="/WhoWeAre" component={WhoWeAre} />
      <Route path="/Molecular" component={Molecular} />
      <Route path="/Histopathology" component={Histopathology} />
      <Route path="/Hematology" component={Hematology} />
      <Route path="/Imaging" component={Imaging} />
      <Route path="/OurMission" component={OurMission} />
      <Route path="/Molecular_Genetics" component={Molecular_Genetics} />
      <Route path="/Covid" component={Covid} />
      <Route path="/Special_Pathology" component={Special_Pathology} />
      <Route path="/Microbiology" component={Microbiology} />
      <Route path="/Login" component={LoginPage} />
      <Route path="/Register" component={RegisterPage} />
      <Route path="/RecallPassword" component={RecallPassword} />
      <Route path="/Quotation" component={Quotation} />
      <Route path="/Emirates" component={Emirates} />
      <Route path="/Lab_Reports" component={Lab_Reports} />
      <Route path="/AirBlue" component={AirBlue} />
      <Route path="/AirBlue_COVID19_PCR" component={AirBlueSummary} />
      <Route path="/Summary" component={Summary} />
      <Route path="/FlyDubaiExcelExport" component={FlyDubaiExcelExport} />
      <Route path="/KSA_Reports" component={CovidReports} />
      <Route path="/Sample_Tracking" component={Sample_Tracking} />
      <Route path="/View_Sample_Tracking" component={View_Sample_Tracking} />
      <Route path="/Update_Tracking" component={Update_Sample_Tracking} />
      <Route path="/FlyDubai" component={FlyDubai} />
      <Route path="/FlyDubai_Covid19_PCR" component={FlyDubaiReports} />

      <Route path="/ExportKSACases" component={ExportKSACases} />

      <Route path="/QatarLogin" component={QatarLogin} />

      <Route path="/Qatar_Covid19_PCR" component={QatarSummary} />

      <Route path="/QatarExcelExport" component={QatarExcelExport} />
      <Route path="/Bookings" component={Bookings} />

      <Route path="/Onix_Enterprises" component={Onix_Enterprises} />

      <Route path="/OnixEnterprises_COVID19_PCR" component={Onix_Summary} />

      <Route path="/GerrysExcelExport" component={GerrySummary} />
      <Route path="/SereneAir" component={SereneAir} />

      <Route path="/SereneAir_COVID19_PCR" component={SereneAir_Summary} />


      <Route path="/EtihadLogin" component={EtihadLogin} />

      <Route path="/EtihadAirways_COVID19_PCR" component={Etihad_Summary} />


      <Route path="/Overall_Audit" component={Gerrys_UTF_Service} />
      <Route path="/OmanAir" component={OmanAir} />

      <Route path="/OmanAir_COVID19_PCR" component={OmanAir_Summary} />

      <Route path="/Travelers" component={Travelers} />

    </Switch>
  </BrowserRouter>

  ,
  document.getElementById("root")
);
