import React,{useEffect} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import moment from 'moment';

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/9440.jpg";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Slide from "@material-ui/core/Slide";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import TextField from '@material-ui/core/TextField';
import { Grid } from "@material-ui/core";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


export default function Sample_Tracking(props) {

    const [invoiceSearch,setInvoiceSearch]=React.useState('')
const [invoiceno,setInvoiceNo]=React.useState('')
const [name,setName]=React.useState('')
const [cnic,setCnic]=React.useState('')
const [passport,setPassport]=React.useState('')
const [invoiceTime,setInvoiceTime]=React.useState('')
const [reportTime,setReportTime]=React.useState('')
const [result,setResult]=React.useState('Select')

  const yesterday = moment().subtract(1, 'day');
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [classicModal, setClassicModal] = React.useState(false);
  const [ServerResponse,setServerResponse]=React.useState('')
  const formatAMPM=(date)=>{
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const classes = useStyles();
  const { ...rest } = props;
  const [reports,setReports]=React.useState('')
 
useEffect(()=>{
    if(localStorage.getItem('role')=='Admin'){
        setReports('true')
       }else{
        window.location.href="https://fslabs.com.pk/"
       }
},[])

  return (
   <>
   {reports=='true' ?
      <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:'#1a5790'}}>
                    <h4 style={{color:'white'}}>Update Arham Services Record</h4>
                    
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                      <GridItem xs={12} xs={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="Invoice No"
                  value={invoiceSearch}
                  onChange={(e)=>{
                    setInvoiceSearch(e.target.value)
                  }} 
                  
                  />
                  </GridItem>
                  <GridItem xs={0} sm={0} md={4} lg={4}>
                      </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <Button color="#1a5790" size="lg" style={{backgroundColor:'#1a5790'}}
                     onClick={async()=>{
                         var data="MPL Labs:79e832dcd81ed3ef079e4b2402f794b8r35oHNBIsNTKEY"
                         let buff = Buffer.from(data);
let base64data = buff.toString('base64');
                         fetch("https://app.arham.services/api/v1/fetchresult?pcode="+invoiceSearch+"&labcode=MPL-202-0810",{
                             headers:{
                                'Authorization':"Basic "+ base64data
                             }
                         }).then((res)=>res.json).then((result)=>{
                             if(result.code==0){
                                setResult("Select")
                                setInvoiceNo("")
                                setCnic("")
                                setPassport("")
                                setInvoiceTime("")
                                setReportTime("")
                setClassicModal(true)
         setServerResponse('Invalid Invoice No!!')
         return
                             }
                             setResult(result.Result)
                             setInvoiceNo(result.PasCode)
                             setCnic(result.PasCNIC)
                             setPassport(result.PasPassport)
                             setInvoiceTime(result.EntryTime)
                             setReportTime(result.ReportTime)
                         })
                     }}
                     >Search</Button>
                  </GridItem>
                  
                  </GridContainer>
                  <br />
                  <br />
                   <GridContainer fullWidth>
                       <GridItem xs={12} sm={12} md={4} lg={4}>
                       <FormControl fullWidth>
                      <TextField required id="" block
                  fullWidth={true}
                  label="Invoice No"
                  disabled={true}
                  value={invoiceno}
                  onChange={(e)=>{
                    setInvoiceNo(e.target.value)
                  }} 
                  
                  />
                    </FormControl>
                       </GridItem>
                       
                           <GridItem xs={0} sm={0} md={4} lg={4}>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} lg={4}>
                    
                       </GridItem>
                       </GridContainer>
                       <br />
                  
                  <br />
                       <GridContainer>
                       <GridItem xs={12} sm={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="name"
                  value={name}
                  onChange={(e)=>{
                    setName(e.target.value)
                  }} 
                  
                  />
                  </GridItem>
                
                           <GridItem xs={0} sm={0} md={4} lg={4}>
                      </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="CNIC"
                  type="number"
                  value={cnic}
                  onChange={(e)=>{
                    setCnic(e.target.value)
                  }} 
                  />
                  </GridItem>
                       </GridContainer>
                       <br />
                  <br />
                       <GridContainer>
                       <GridItem xs={12} sm={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="Passport No"
                  type="number"
                  value={passport}
                  onChange={(e)=>{
                    setPassport(e.target.value)
                  }} 
                  
                  />
                  </GridItem>
                 
                           <GridItem xs={0} sm={0} md={4} lg={4}>
                      </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="Invoice Date & Time"
                  value={invoiceTime}
                  onChange={(e)=>{
                    setInvoiceTime(e.target.value)
                  }} 
                  
                  />
                  </GridItem>
                       </GridContainer>
                       <br />
                  <br />
                       <GridContainer>
                       <GridItem xs={12} sm={12} md={4} lg={4}>
                  <TextField required id="" block
                  fullWidth={true}
                  label="Report Date & Time"
                  value={reportTime}
                  onChange={(e)=>{
                    setReportTime(e.target.value)
                  }} 
                  
                  />
                  </GridItem>
                 
                           <GridItem xs={0} sm={0} md={4} lg={4}>
                      </GridItem>
                  <GridItem xs={12} sm={12} md={4} lg={4}>
                  <FormControl variant="filled" className={classes.formControl}  style={{width:"-webkit-fill-available"}} >
        <InputLabel id="demo-simple-select-filled-label">Result</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={result}
          onChange={(e)=>{
       setResult(e.target.value)
          }}
        >
        <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"positive"}>Positive</MenuItem>
          <MenuItem value={"negative"}>Negative</MenuItem>
        </Select>
      </FormControl>
                  </GridItem>
                       </GridContainer>
                       

                     <br />

                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="#1a5790" size="lg" style={{backgroundColor:'#1a5790'}}
                     onClick={async()=>{
                      if(invoiceno==''){
                        setClassicModal(true)
                        setServerResponse('Invalid Invoice Number!')
                        return
                       }
                       if(cnic.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Invalid CNIC!!')
                        return
                       }
                       if(passport.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Invalid Passport No!!')
                        return
                       }
                      
                       if(reportTime==''){
                        setClassicModal(true)
                        setServerResponse('Invalid Report Time!')
                        return
                       }
                       if(invoiceTime==''){
                        setClassicModal(true)
                        setServerResponse('Invalid Invoice Time!')
                        return
                       }
                       if(result=='Select'){
                        setClassicModal(true)
                        setServerResponse('Invalid Result!')
                        return
                       }
                       var data="MPL Labs:79e832dcd81ed3ef079e4b2402f794b8r35oHNBIsNTKEY"
                       let buff = Buffer.from(data);
let base64data = buff.toString('base64');
            
                       fetch("https://arham.services/api/v1/pushdata.php?pcode="+invoiceno+"&pname="+name+"&ppass="+passport+"&pcnic="+cnic+"&sampt="+invoiceTime+"&pres="+reportTime+"&rept="+result+"&tname=COVID PCR - Molecular",{
                        headers:{
                            'Authorization':"Basic "+ base64data
                         }
                    }).then((response) => {
                          return response.json();
                       }).then((res)=>{
                        if(res.code==1){
                          setClassicModal(true)
                          setServerResponse('Invoice Updated!!')
                          setTimeout(()=>{
                            window.location.reload()
                          },3000)
                        return
                        }else{
                          setClassicModal(true)
                          setServerResponse('Sorry , Failed to Update!!')
                          return
                      
                         }
                      })
                       
                       
                     }}
                    >
                      Submit
                    </Button>
                  </CardFooter>
                </form>
              </Card>
            
         
          <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Patient Registration</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                      {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>

        </div>
       
      </div>
    </div>:
    <></>
   }</>
  );
}
