import React from 'react';
import { Page, Document, Image, StyleSheet, View, Text, Font } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'
import InvoiceThankYouMsg from './InvoiceThankYouMsg'

import logo from '../mpl-logo.jpg'
import footer from '../mpl-footer.jpg'
import sign from '../sign.png'
import stamp from '../stamp.png'


Font.register({ family: 'Calibri Regular', src: require('../../../fonts/Calibri Regular.ttf'), });

Font.register({ family: 'Calibri Bold', src: require('../../../fonts/calibri-bold.ttf') });
const styles = StyleSheet.create({
  page: {
    paddingTop: 150, paddingBottom: 50,
    fontSize: 8,
    lineHeight: 1.5,
    flexDirection: 'column'
  },
  box: { width: '100%', marginBottom: 30, borderRadius: 5 },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
  qrcode: {
    width: 60,
    height: 60
  },
  logo: {
    width: 170,
    height: 90

  },
  barcode: {
    width: 150,
    height: 40
  }
});


const CovidTemplate = ({ invoice }) => {

  return (<Document

  >
    <Page style={styles.page} size="A4" wrap
    >
      <View fixed style={{
        width: '100%', display: 'flex', flexDirection: 'row', top: 0, position: 'absolute',
        paddingLeft: 20,
        alignItems: "center",
        fontFamily: "Calibri Regular",
        paddingRight: 20,
        height: 210,
        paddingTop: 10
      }} >
        <Image style={styles.logo} src={logo} />

        {
   invoice.Airline.toLowerCase().includes('turkish') && invoice.Base64_PicturePatient!="data:image/png;base64," ?
   <View style={{ marginLeft: 50, flexDirection: "column" }}>
   <View style={{
     margin: 'auto', 
     width: 80, 
     backgroundColor: '#F2F0F0', 
     borderWidth: 1,
     position:"absolute",
     borderColor: 'black',
     marginTop:20,
     marginLeft:40
   }}>
     <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 9 }}>MPL ID : {invoice.invoice_id}</Text>
   </View>
  
   <View

     style={{
       marginLeft: 10,
       marginTop: 10,
       flexDirection:"row"
     }}
   >
    
   
    <Text style={{
        marginTop: 40,
        marginRight:-45,
        fontSize:9
     }}>Online</Text>
     <Image style={{
       width: 70,
       height: 70,
       marginTop: 50,
       marginRight:10
     }} src={invoice.QR_Code_Online} />
     <Image style={{
         width: 100,
         height: 100,
         marginTop:50
     }} src={invoice.Base64_PicturePatient} />
     
   </View>

 </View>
 :
 <View style={{ marginLeft: 80, flexDirection: "column" }}>
          <View style={{
            margin: 'auto', 
            width: 80, 
            backgroundColor: '#F2F0F0', 
            borderWidth: 1,
            position:"absolute",
            borderColor: 'black',
            marginTop:-30,
            marginLeft:40
          }}>
            <Text style={{ paddingTop: '3em', paddingLeft: '5em', paddingRight: '5em', textAlign: 'center', fontFamily: 'Helvetica-Bold', fontSize: 9 }}>MPL ID : {invoice.invoice_id}</Text>
          </View>
          <View

            style={{
              marginLeft: 40,
              marginTop: 10
            }}
          >

            <Text style={{
              paddingLeft: 20,
              fontSize: 10,
              marginTop: 10
            }}>Online</Text>
            <Image style={{
              width: 70,
              height: 70,
              marginTop: -5
            }} src={invoice.QR_Code_Online} />
          </View>

        </View>
}
       


        <View style={{ marginLeft:  invoice.Airline.toLowerCase().includes('turkish') && invoice.Base64_PicturePatient!="data:image/png;base64," ? 10 : 43, marginTop: 5 }}  >
      
          <Image style={styles.barcode} src={invoice.barcode} />

          <Text style={{
            marginLeft: '5em',
            fontFamily: 'Calibri Bold',
            fontSize: 9,
            marginTop: 5,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,
          }}>{invoice.toAddress + " " + invoice.name}</Text>
          <Text style={{
            marginLeft: '5em',
            paddingTop: '5em',
            fontSize: 6,
            paddingTop: 3,
            paddingBottom: 2,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,

          }}>{"Age/Gender    :  " + invoice.age_gender}</Text>
           <Text style={{
            marginLeft: '5em',
            paddingTop: '5em',
            fontSize: 6,
            paddingTop: 3,
            paddingBottom: 2,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,

          }}>{"Date of Birth   :  " + invoice.StringDOB}</Text>
          {
            invoice.passport != ""
              ?
              <Text style={{
                marginLeft: '5em',
                paddingTop: '5em',
                fontSize: 6,
                paddingTop: 3,
                paddingBottom: 2,
                borderBottomColor: 'black',
                borderBottomWidth: 0.3,

              }}>{"Passport No    :  " + invoice.passport}</Text>
              :
              null
          }
          {
            (invoice.Airline.toLowerCase().includes('qatar') || invoice.Airline.toLowerCase().includes('turkish') || invoice.Airline.toLowerCase().includes('etihad'))
              ?
              <View>
                <Text style={{
                  marginLeft: '5em',
                  paddingTop: '5em',
                  fontSize: 6,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Flight No         :  " + invoice.flight_no}</Text>
                <Text style={{
                  marginLeft: '5em',
                  paddingTop: '5em',
                  fontSize: 6,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Flight Date      :  " + invoice.flight_date}</Text>
                <Text style={{
                  marginLeft: '5em',
                  paddingTop: '5em',
                  fontSize: 6,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Ticket No         :  " + invoice.ticket_no}</Text>
              </View>
              : null
          }
          {invoice.title == "Covid-19 RNA by PCR" ?
            <Text style={{
              marginLeft: '5em',
              paddingTop: '5em',
              fontSize: 6,
              paddingTop: 3,
              paddingBottom: 2,
              borderBottomColor: 'black',
              borderBottomWidth: 0.3,

            }}>{"CNIC #              :  " + invoice.cnic}</Text>
            : null
          }
          {
            invoice.title == "Covid-19 RNA by PCR" || invoice.ShouldSampleDateTimePrint
              ?
              <View>
                <Text style={{
                  marginLeft: '5em',
                  paddingTop: '5em',
                  fontSize: 6,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Sample Date   :  " + invoice.specimen_date}</Text>
                <Text style={{
                  marginLeft: '5em',
                  paddingTop: '5em',
                  fontSize: 6,
                  paddingTop: 3,
                  paddingBottom: 2,
                  borderBottomColor: 'black',
                  borderBottomWidth: 0.3,

                }}>{"Sample Time   :  " + invoice.specimen_time}</Text>


              </View>
              : null

          }
          <Text style={{
            marginLeft: '5em',
            paddingTop: '5em',
            fontSize: 6,
            paddingTop: 3,
            paddingBottom: 2,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,

          }}>{"Report Date    :  " + invoice.result_date}</Text>
          <Text style={{
            marginLeft: '5em',
            paddingTop: '5em',
            fontSize: 6,
            paddingTop: 3,
            paddingBottom: 2,
            borderBottomColor: 'black',
            borderBottomWidth: 0.3,

          }}>{"Report Time    :  " + invoice.result_time}</Text>

        </View>


      </View>
      <View style={{ width: '100%', height: '100%', marginTop: -40, paddingRight: 20, paddingLeft: 20 }}

      >

        <InvoiceItemsTable invoice={invoice}
        />

      </View>



      <View fixed style={{ position: 'absolute', width: '100%', bottom: 0, marginTop: -10 }}>
        <View style={{
          display: "flex",
          flex: 1,
          width: "100%",
          flexDirection: 'row'
        }}>
          {
            invoice.Airline.toLowerCase().includes('turkish') ?
              <View
                style={{
                  width: "25%",
                  paddingLeft: 40
                }}
              >
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: "100%",
                  marginTop: 5,

                  fontSize: 9
                }}>
                  <View style={{
                    flexDirection: 'row'
                  }}>
                    <Text>e-Mail</Text>
                    <Text style={{
                      fontSize: 9,
                      color: "black",
                      fontFamily: 'Helvetica-Bold',
                      marginLeft: 20,
                      width: "100%"
                    }}>{invoice.list[0].e_mail}</Text>
                  </View>
                </View>
                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: "100%",
                  marginTop: 5,
                  fontSize: 9,

                }}>
                  <View style={{
                    flexDirection: 'row'
                  }}>
                    <Text>Contact #</Text>
                    <Text style={{
                      fontSize: 9,
                      color: "black",
                      fontFamily: 'Helvetica-Bold',
                      marginLeft: 20
                    }}>{invoice.list[0].contact}</Text>
                  </View>
                </View>
              </View>
              :
              <View
                style={{
                  width: "25%"
                }} ></View>}
          <View
            style={{
              width: "50%",
              alignItems: "center"
            }}
          >
            <Text style={{
              marginLeft: -4,
              marginTop: 5
            }}>Offline</Text>
            <Image style={styles.qrcode} src={invoice.QR_Code_Offline} />
          </View>
          <View
            style={{
              width: "25%"
            }}
          >

          </View>
        </View>
        <View style={{ alignItems: 'flex-end', marginRight: 20, marginBottom: 0 }}>


          <Text style={{ fontSize: 7 }}>User ID : {invoice.user_id}  </Text>
          <Text style={{ color: 'red', bottom: 0, right: 0, fontFamily: 'Calibri Bold' }}>This is a digitally verified Report and does not require any Signature</Text>

        </View>
        <View >
          <Image src={footer} style={{height:100}} />
        </View>
      </View>
    </Page>
  </Document>
  )
}

export default CovidTemplate