import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';



const InvoiceTableRow = ({items}) => {
    const rows = items.map( item => 
        
        <View key={item.sn+"321323"}>
        <View style={ {
        flexDirection: 'row',
        borderBottomColor: 'BLACK',
        borderBottomWidth: 1,
        alignItems: 'center',
        width:"100%",
        fontSize:7,
        fontStyle: 'bold'
        }} key={item.sn}>
             <Text style={{ 

        width: '5%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        height:'100%',
        padding:5,  
        fontSize:7,
        }}>{item.sn}</Text>
         <Text style={{ 
                 
        width: '25%',
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        fontSize:6,
        height:'100%',
        padding:5
        
        
        }}>{item.test_title}</Text>
         <Text style={{ 
                 
        width: '25%',
        textAlign: 'left',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        
        fontSize:6,
        padding: 5,
        height:'100%'
        
        
        }}>{item.reporting_date}</Text>
         <Text style={{ 
                 
        width: '15%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        
        fontSize:6.5,
        height:'100%',
        padding: 5,
        
        }}>{item.fee}</Text>
         <Text style={{ 
                 
        width: '15%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        height:'100%',
        
        fontSize:6.5,
        padding: 5,
        
        
        }}>{item.disc}</Text>
            <Text style={{ 
                 
        width: '15%',
        height:'100%',
        textAlign: 'right',
        borderLeftColor: 'black',
        borderLeftWidth: 1,
        fontSize:6.5,
        borderRightWidth: 1,
        textOverflow: 'auto',
        padding: 5
        
        }}>{item.net_value}</Text>
           </View>
           <View style={ {
        flexDirection: 'row',
        marginTop:5,
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold'
        }} key={item.sn+"q123"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 1,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Invoice Net Payable"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 1,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{item.net_value}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold'
        }} key={item.sn+"21"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 1,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Total Received Value"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 1,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                 }}>{item.net_value}</Text>

            </View>
            <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        height:20,
        fontSize:7,
        fontStyle: 'bold'
        }} key={item.sn+"43"}>
              <Text style={{ 

width: '55%',
textAlign: 'left',
height:'100%',
padding:5,  
fontSize:7,
}}>

</Text>
                  <Text style={{ 
                          
                 width: '30%',
                 textAlign: 'left',
                 borderColor: 'black',
                 borderWidth: 1,
                 height:'100%',
                 
                 fontSize:6.5,
                 padding: 5,
                 
                 
                  }}>{"Balance Value"}</Text>
                     <Text style={{ 
                          
                 width: '15%',
                 height:'100%',
                 textAlign: 'right',
                 borderColor: 'black',
                 borderWidth: 1,
                 backgroundColor:"#eeeeee",
                 fontSize:6.5,
                 borderRightWidth: 1,
                 textOverflow: 'auto',
                 padding: 5
                 
                     }}>{"0.00"}</Text>

            </View>
            <View key={item.sn+"4323"}>
                   
                         
                   <Text style={{ 
                
                textAlign: 'left',
                padding:2, 
                fontFamily: 'Helvetica-Bold',
                fontSize:6.5,
                color:'black'
                }}>
                {"I have read and verified my particulars as correct"}
                </Text>
               
                            </View>
                            <View key={item.sn+"4323"}>
                           
                                 
                           <Text style={{ 
                        
                        textAlign: 'left',
                        padding:2, 
                        fontSize:6.5,
                        color:'black'
                        }}>
                        {"Name : "+item.name}
                        </Text>
                        <Text style={{ 
                        
                        textAlign: 'left',
                        padding:2, 
                        fontSize:6.5,
                        color:'black'
                        }}>
                        {"CNIC : "+item.cnic}
                        </Text>
                        {
                            item.flight_no!="" ?
                            <Text style={{ 
                        
                                textAlign: 'left',
                                padding:2, 
                                fontSize:6.5,
                                color:'black'
                                }}>
                                {"Passport No : "+item.passport}
                                </Text>
                                : null
                        }
                        <Text style={{ 
                
                textAlign: 'left',
                padding:2, 
                fontSize:6.5,
                color:'black'
                }}>
                {"User : "+item.user_id}
                </Text>
                        
                       
                                    </View>
                     <View style={{
                         height:30
                     }} key={item.sn+"542343"}>
                           
                                  <Text style={{ 
                                          
                                 width: '40%',
                                 textAlign: 'left',
                                 borderColor: 'black',
                                 borderWidth: 1,
                                 fontSize:6.5,
                                 padding: 7,
                                 
                                 
                                  }}>{"Signature"}</Text>
                                    
                   <Text style={{ 
                
                width: '60%',
                textAlign: 'left',
                height:'100%',
                padding:5,  
                fontSize:7,
                }}>
                
                </Text>
                
                            </View>
           </View>
    )
    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow