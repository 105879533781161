import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position:'relative',
    textAlign:'left'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),

    fontWeight:"bold"
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Saudi Arabia</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         <ol>
           <li>PIA</li>
           <li>Emirates Air</li>
           <li>Qatar Airways</li>
           <li>Saudi Airline</li>
           <li>Air Arabia</li>
           <li>FlyDubai</li>
           <li>Kuwait Airways</li>
           <li>Oman Air</li>
           <li>Salam Air</li>
           <li>Gulf Air</li>
           <li>Turkish Air</li>
           <li>Air Blue</li>
           <li>Jazeera Airways</li>
           <li>Serene Air</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>State of Qatar</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>Qatar Airways</li>
           <li>AirBlue</li>
           <li>Emirates Air</li>
           <li>FlyDubai</li>
           <li>Air Arabia</li>
           <li>Salam Air</li>
           <li>Oman Air</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>UAE</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>PIA (Dubai & Sharjah Only)</li>
           <li>AirBlue (All UAE)</li>
           <li>Emirates Air (All UAE)</li>
           <li>FlyDubai (All UAE)</li>
           <li>Air Arabia (All UAE)</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>United Kingdom (UK)</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>British Airways</li>
           <li>Virgin Atlantic</li>
           <li>PIA</li>
           <li>Emirates Air</li>
           <li>FlyDubai</li>
           <li>Air Arabia</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Oman/Muscat</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>PIA</li>
           <li>Emirates Air</li>
           <li>FlyDubai</li>
           <li>Air Arabia</li>
           <li>Oman Air</li>
           <li>Gulf Air</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Canada</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>PIA</li>
           <li>Emirates Air</li>
           <li>FlyDubai</li>
           <li>Air Arabia</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
