/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { NavLink } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, ImportantDevices } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import url from '../../link'
const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  var Main_Menu= localStorage.getItem('role')=='Patient' ?
  [ 
  <NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>
,
<NavLink to="/ResetPassword" className={classes.dropdownLink}>
Reset Password
</NavLink>]
:
[<NavLink to="/FindTest" className={classes.dropdownLink}>
Find a Test
</NavLink>,
<NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,
<NavLink to="/ResetPassword" className={classes.dropdownLink}>
Reset Password
</NavLink>]

  var ksa = localStorage.getItem('patient') ?
  (!localStorage.getItem('patient_name').toLowerCase().includes("mpl-") && (localStorage.getItem('patient_id')=="najam" || localStorage.getItem('patient_id')=="najam1" ))  ? 
  [
] 
:
( localStorage.getItem('patient_name').toLowerCase().includes("mpl-")  && localStorage.getItem('role')=='Admin' )
?
[ ]

:[]:[]

  return (
    <List className={classes.list}>
      
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
        <Button
          style={{backgroundColor:'#1a5790'}}
          
        >
          <NavLink to="/" 
          style={{color:'white'}}
          >Home </NavLink>
        </Button>
      </ListItem>
     
     
      {localStorage.getItem('patient')==null ? 
      
      <ListItem className={classes.listItem} style={{marginRight:'20px', textAlign:'center'}}>
      <Button
        className={'online_reports'}
        
      >
        <NavLink to="/Login" 

        style={{color:'white'}}
      

        >Online Reports</NavLink>
      </Button>
    </ListItem>
    : <></>
    }
       
       
      { localStorage.getItem('patient') && localStorage.getItem('role')!='Admin' ? 
         <>
          <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
        <CustomDropdown
          buttonText="Main Menu"
          style={{backgroundColor:'#1a5790'}}
          className={classes.navLink}
          dropdownList={[
           ...Main_Menu,
<Button 
style={{textAlign:'center', width:'100%'}}
  onClick={()=>{
    localStorage.clear()
    setTimeout(()=>{
      window.location.reload()
    },1000)
    window.location.href=url
   
  }}
className={classes.dropdownLink}>
  Logout
</Button>


          ]}
        />
      </ListItem>
       
      </>
      :
      localStorage.getItem('patient') && localStorage.getItem('role')=='Admin' ?
      <ListItem className={classes.listItem} style={{textAlign:'center', marginRight:'1em'}}>
      <CustomDropdown
        buttonText="Main Menu"
        style={{backgroundColor:'#1a5790'}}
        className={classes.navLink}
        dropdownList={[
          
          <NavLink to="/Lab_Reports" className={classes.dropdownLink}>
Lab Reports
</NavLink>,
<Button 
style={{textAlign:'center', width:'100%'}}
onClick={()=>{
  localStorage.clear()
  setTimeout(()=>{
    window.location.reload()
  },1000)
  window.location.href=url
 
}}
className={classes.dropdownLink}>
Logout
</Button>


        ]}
      />
    </ListItem>
      :
     null
      
      }
       {localStorage.getItem('patient')  ? 
       <ListItem className={classes.listItem} style={{textAlign:'center'}}>
       <Button
          style={{backgroundColor:'white',   boxShadow:
          "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
      }}
        
       >
       <img src={require('assets/img/welcome.png')}
       style={{width:'3em'}}
       />
      <b style={{color:'black'}}> Welcome
      
        <br />{localStorage.getItem('patient_name')}</b>

       </Button>
     </ListItem>
      :<></> }
      
    </List>
  );
}
