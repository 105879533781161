import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';



import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';
import AboutSection from './AboutSection'


import test_info from 'assets/img/test_info.png';
import MissionSection from './MissionSection'

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/building.jpeg")}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            
           
              <h1 className={classes.title}>Mission & Vision</h1>
              
              <br />
            
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{paddingTop:"1em", paddingBottom:"5em"}} >
        <GridContainer justify="center">
              <COVID19_PCR />
              </GridContainer>
          <br />
           <MissionSection />
            <FreeHS_Msg />
         
        </div>
      </div>
      <Footer />
    </div>
  );
}
