import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";

import history from 'assets/img/history.png';
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import History_Table from './History_Table'


import styles from "assets/jss/material-kit-react/views/landingPage.js";
// Sections for this page
const dashboardRoutes = [];

const useStyles = makeStyles(styles);



export default function Booking_History(props) {
  const classes = useStyles();
 
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/info.jpeg")} style={{maxHeight:'25em'}}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Booking History</h1>
              
              <br />
          
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main)}>
        <div className={classes.container} style={{ paddingBottom:"5em"}} >
         
           <br />
           <h4 style={{color:'black'}}>Status</h4>
           <GridContainer>
          <GridItem xs={3} sm={3} md={3} lg={1}>
            <div style={{backgroundColor:'red', width: "50%",
            height: "50%"}}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={2}>
              <p style={{color:'black'}}>Request of Home Sampling has been received</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={1}>
            <div style={{backgroundColor:'yellow', width: "50%",
            height: "50%"}}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={2}>
              <p style={{color:'black'}}>Phlebotomist is on the Way</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} md={1}>
            <div style={{backgroundColor:'blue', width: "50%",
            height: "50%"}}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={2}>
              <p style={{color:'black'}}>Phlebotomist has received sample from patient</p>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} md={1}>
            <div style={{backgroundColor:'green', width: "50%",
            height: "50%"}}>
              </div>
            </GridItem>
            <GridItem xs={3} sm={3} md={3} lg={2}>
              <p style={{color:'black'}}>Phlebotomist has deliver sample to laboratory</p>
            </GridItem>
            </GridContainer>
           <br />
          <img src={history} style={{
  width: '7em',}} class="center"/>
          <History_Table />
        </div>
      </div>
      <Footer />
    </div>
  );
}
