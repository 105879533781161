import React from 'react';
import {View, StyleSheet, Text  } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import InvoiceTitle from './InvoiceTitle'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderWidth: 0.5,
        fontFamily:"Calibri Regular",
        borderColor: 'black'
    },
});

  const InvoiceItemsTable = ({invoice}) => (
  invoice.list.map((item)=>{
   return  <View>
      <InvoiceTitle title={item.title} />
   <View style={styles.tableContainer}>
   <InvoiceTableHeader  date={invoice.result_date}/>
   <InvoiceTableRow items={item.reports} />
</View>
<View style={{paddingLeft:10,
 paddingTop:10,
 marginTop:10,
 borderWidth:0.5,
 borderColor:'black'
}}>
     <Text style={{
       fontSize:8,
       fontFamily:'Calibri Bold'
      
     }}>Methodology:</Text>
     <Text
     style={{
       padding:2,
       paddingRight:10,
       paddingLeft:10,
       fontFamily:"Calibri Regular"
     }}
     >{`Test is performed on state of the art COVID-19 testing system (HG COVID-19 & HG Swift analyzer), a CE marked & IVD approved system, from Hibergene Diagnostics Limited, Ireland. The HiberGene COVID-19 assay is a LAMP-based molecular diagnostic test for the detection of the SARS-CoV-2 virus in upper respiratory swab samples. The HG COVID-19 assay utilizes isothermal LAMP technology to deliver a rapid and sensitive testing solution for COVID-19, with an integrated sample handling control. HiberGene’s embodiment of LAMP technology (loop-mediated isothermal amplification) employs novel primer design, a highly efficient strand-displacing polymerase and fluorescent probes to facilitate specific fluorescent detection at constant temperature.`}</Text>
    
     <Text style={{
       fontSize:8,
       fontFamily:'Calibri Bold'
      
     }}>Specimen:</Text>
     <Text
     style={{
       padding:2,
       paddingRight:10,
       paddingLeft:10,
       fontFamily:"Calibri Regular"
     }}
     >{`Nasopharyngeal Swab`}</Text>
    
     <Text style={{
       fontSize:8,
       fontFamily:'Calibri Bold'
      
     }}>RNA Extraction:</Text>
     <Text
     style={{
       padding:2,
       paddingRight:10,
       paddingLeft:10,
       fontFamily:"Calibri Regular"
     }}
     >{`RNA is extracted and extracted Nucleic Acid used for the testing.`}</Text>
    
     <Text style={{
       fontSize:8,
       fontFamily:'Calibri Bold'
      
     }}>Comments/Recommendations:</Text>
       <View
     style={{
       padding:2,
       flexDirection:'row',
       paddingRight:10,
       paddingLeft:10,
       fontFamily:"Calibri Regular"
     }}
    ><Text style={{padding:2,
      fontFamily:'Calibri Bold'}}>{"o  "}</Text><Text style={{paddingTop:4}}>{`A negative result must be interpreted along with clinical observation, patient history and epidemiological information.`}</Text></View>
   <View
     style={{
       padding:2,
       flexDirection:'row',
       paddingRight:10,
       paddingLeft:10,
       fontFamily:"Calibri Regular"
     }}
    ><Text style={{padding:2,
      fontFamily:'Calibri Bold'}}>{"o  "}</Text><Text style={{paddingTop:4}}>{`A single negative result might not exclude the possibility of corona virus infection. A repeat test may be required if symptoms persist.`}</Text></View>
     <View
     style={{
       padding:2,
       flexDirection:'row',
       paddingRight:10,
       paddingLeft:10,
       fontFamily:"Calibri Regular"
     }}
    ><Text style={{padding:2,
      fontFamily:'Calibri Bold'}}>{"o  "}</Text><Text style={{paddingTop:4}} >{`In case of positive result, it is strongly advised that patient should stay at home under self-quarantine and maintain social distancing along with
    additional testing as deemed necessary by treating physician.`}</Text></View>
  
  <View
     style={{
       padding:2,
       flexDirection:'row',
       paddingRight:10,
       paddingLeft:10,
       fontFamily:"Calibri Regular"
     }}
    ><Text style={{padding:2,  fontFamily:'Calibri Bold'}}>{"o  "}</Text ><Text style={{paddingTop:4}}>{`In case of the patient develops shortness of breath he/she should immediately seek medical advice`}</Text></View>
        <View
     style={{
       padding:2,
       flexDirection:'row',
       paddingRight:10,
       paddingLeft:10,
        fontFamily:"Calibri Regular"
     }}
    ><Text style={{padding:2,  fontFamily:'Calibri Bold'}}>{"o  "}</Text><Text style={{paddingTop:4}}>{`Results of PCR studies performed in one laboratory should not be compared with those performed in another laboratory as the kits/methodologies
    used in different laboratories may not have the same sensitivity and specificity.`}</Text></View>
     </View>

     
<View style={{
  padding:10
}}>
<Text style={{
    fontSize:10,
    fontFamily:'Helvetica-Bold'
}}>This report can be verified at</Text>
  <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        marginTop:5,
        fontSize:9,
        
        }}>
          <View style={{
            width:"25%",
            justifyContent:'space-between',
            flexDirection: 'row'
          }}>
          <Text>Weblink </Text>
          <Text style={{  fontSize:9,
          color:"#0060ff",
    fontFamily:'Helvetica-Bold'}}>fslabs.com.pk/Login</Text>
          </View>
          <View style={{
            width:"27%",
            justifyContent:'space-between',
            flexDirection: 'row',
          marginLeft:15,
          }}>
          <Text>Android App</Text>
          <Text style={{  fontSize:9,
          color:"#0060ff",
    fontFamily:'Helvetica-Bold'}}>fslabs.com.pk/android</Text>
          </View>
          <View style={{
            width:"20%",
            justifyContent:'space-between',
            flexDirection: 'row',
            marginLeft:15
          }}>
          <Text>iOS App </Text>
          <Text style={{  fontSize:9,
          color:"#0060ff",
    fontFamily:'Helvetica-Bold'}}>fslabs.com.pk/ios</Text>
          </View>
          </View>
          <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        marginTop:5,
        fontSize:9,
      
        }}>
         <View style={{
            width:"19%",
            justifyContent:'space-between',
            flexDirection: 'row'
          }}>
          <Text>Login ID</Text>
          <Text style={{  fontSize:9,
          color:"black",
          fontFamily:'Helvetica-Bold'}}>{item.id}</Text>
          </View>
        </View>
        <View style={ {
        flexDirection: 'row',
        alignItems: 'center',
        width:"100%",
        marginTop:5,
        fontSize:9,
       
        }}>
          <View style={{
            flexDirection: 'row'
          }}>
          <Text>Password</Text>
          <Text style={{  fontSize:9,
          color:"black",
          fontFamily:'Helvetica-Bold',
          marginLeft:19}}>{item.password}</Text>
          </View>
        </View>
        </View>
<View>
</View>
<View>

  </View>
</View>
  })
  
  );
  
  export default InvoiceItemsTable