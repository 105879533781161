import React, {Fragment} from 'react';
import {Text, View, StyleSheet, Font } from '@react-pdf/renderer';
const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bf',
        borderBottomWidth: 1,
        fontFamily:'Helvetica',
        alignItems: 'center',
        height: 150,
        fontStyle: 'bold',
    },
    description: {
        width: '60%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    rate: {
        width: '15%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const InvoiceTableRow = ({items}) => {
    var rows=[] 
    var total_bill=0
    var counter=0
    items.map( item => {
        total_bill=total_bill+parseInt(item.Test_Price)
        counter=counter+1
        return rows.push(<View style={ {
        
            flexDirection: 'row',
            borderTopColor: 'BLACK',
            borderTopWidth:1,
            alignItems: 'center',
            width:"100%",
            fontStyle: 'bold'
            }} key={item.Test_Name}>
                <Text style={{ 
                     
                     width: '5%',
                     textAlign: 'left',
                     borderLeftColor: 'black',
                     borderLeftWidth: 1,
                     paddingLeft: 8,
                     height:'100%',
                     
                     
                     }}>{counter}</Text>

                 <Text style={{ 
                     
            width: '25%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            paddingLeft: 8,
            height:'100%',
            
            
            }}>{item.Test_Name}</Text>
             <Text style={{ 
                     
            width: '10%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 8
            
            
            }}>{item.Test_Code}</Text>
             <Text style={{ 
                     
            width: '30%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            paddingLeft: 8,
            height:'100%'
            
            
            }}>{item.Sample_Type}</Text>
             <Text style={{ 
                     
            width: '20%',
            textAlign: 'left',
            borderLeftColor: 'black',
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 5,
            
            }}>{item.Delivery_Time}</Text>
             <Text style={{ 
                     
            width: '10%',
            textAlign: 'center',
            borderLeftColor: 'black',
            borderRightWidth:1,
            borderLeftWidth: 1,
            height:'100%',
            paddingLeft: 5,
            
            
            }}>{"Rs "+item.Test_Price+"/-"}</Text>
               
            </View>)
            }
    )
    if(items.length<100){
        rows.push(<View style={ {
        
            flexDirection: 'row',
             alignItems: 'center',
            width:"100%",
            borderTopWidth:1,
            borderTopColor:'black',
            fontStyle: 'bold'
            }} key={"test_1"}>
                 <Text style={{ 
                     
            width: '30%',
            textAlign: 'left',
            paddingLeft: 8,
            height:'100%',
            
            
            }}></Text>
             <Text style={{ 
                     
            width: '10%',
            textAlign: 'center',
            height:'100%',
            paddingLeft: 8
            
            
            }}></Text>
             <Text style={{ 
                     
            width: '30%',
            textAlign: 'left',
            paddingLeft: 8,
            height:'100%'
            
            
            }}></Text>
             <Text style={{ 
                     
            width: '20%',
            height:'100%',
            borderLeftColor: 'white',
            borderLeftWidth: 1,
            paddingLeft: 5,
            fontFamily:'Helvetica-Bold',
            fontSize:8,
            textAlign:'center',
            color:'white',
            backgroundColor: '#696969'
            
            
            }}>Total Price</Text>
             <Text style={{ 
                     
            width: '10%',
            height:'100%',
            borderLeftColor: 'white',
            borderLeftWidth: 1,
            paddingLeft: 5,
            fontFamily:'Helvetica-Bold',
            fontSize:8,
            textAlign:'center',
            color:'white',
            backgroundColor: '#696969'
             
            
            }}>{"Rs "+total_bill+"/-"}</Text>
               
            </View>)
    }else{
        rows.push(<View style={ {
        
            flexDirection: 'row',
             alignItems: 'center',
            width:"100%",
            borderTopWidth:1,
            borderTopColor:'black',
            fontStyle: 'bold'
            }} key={"test_1"}>
                 <Text style={{ 
                     
            width: '100%',
            textAlign: 'left',
            paddingLeft: 8,
            height:'100%',
            
            
            }}></Text>
            </View>)
    }
    

    return (<Fragment>{rows}</Fragment> )
};
  
  export default InvoiceTableRow