import React ,{useEffect} from 'react';

import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';

import Card from "@material-ui/core/Card";
import TableHead from '@material-ui/core/TableHead';

import IconButton from "@material-ui/core/IconButton";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import Typography from "@material-ui/core/Typography";
import TablePagination from '@material-ui/core/TablePagination';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

import CardContent from "@material-ui/core/CardContent";
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';

import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";


import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import ReactExport from 'react-data-export';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { SettingsInputComponentTwoTone } from '@material-ui/icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const columns = [
  { id: 'covid', label: 'Detail' },
  { id: 'covid_cases', label: 'Covid19 Cases' },
  { id: 'panel', label: 'Panel' },
  { id: 'branch', label: 'Branch' },
  { id: 'positive', label: 'Positive' },
  { id: 'negative', label: 'Negative' }
];
const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  container: {
    maxHeight: 1000
  }
});
function msToTime (ms) {
  var seconds = (ms/1000);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return hours + ':' + minutes ;
}
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});
var BranchCounter={}

export default function Lab_Tests() {
  const classes = useStyles();
  const [to,setTo]=React.useState(new Date())
  const [from,setFrom]=React.useState(new Date())
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows,setRows]= React.useState([]);
  const [org_rows,setOrg_Rows]= React.useState([]);
  const [summaryDate,setSummaryDate]=React.useState([]);
  const [loading,setLoading]=React.useState(true)
  const [panel,setPanel]=React.useState('All')
  const [branchDetail,setBranchDetail]=React.useState([]);
  const [data_export,setData_Export]=React.useState([])
  const [selectedDate,setSelectedDate]=React.useState(new Date())
  const [BranchbyName,setBranchbyName]=React.useState({})
  const [open, setOpen] = React.useState(false);
  const [graph_data,setDataGraph]=React.useState([])
  
  const [panelList,setpanelList]=React.useState([])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch,setBranch]=React.useState('All')
  const [state, setState] = React.useState({
    status:'All',
  });

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    
  
    return (
      <React.Fragment>
        <TableRow className={classes.root} key={props.key}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell scope="row">{row.covid_cases}</TableCell>
          <TableCell scope="row">{row.branch}</TableCell>
          <TableCell scope="row">{row.panel}</TableCell>
          <TableCell scope="row">{row.positive}</TableCell>
          <TableCell scope="row">{row.negative}</TableCell>
        </TableRow>
        <TableRow>
               <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                 <Collapse in={open} timeout="auto" unmountOnExit>
                   <Box margin={1}>
                   <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent>
        <GridContainer>
        {
          Object.keys(BranchCounter).map((item)=>{
return (<>
<GridItem md={4}>
<Typography
  className={classes.title}
  variant="h5" component="h2"
>
  {item.split('MPL, ')[1]}
</Typography>
<Typography >
{BranchCounter[item]}
</Typography>
</GridItem>
</>)
          })
        }
          </GridContainer>
          
            </CardContent>
            </div>
            </Card>
                   </Box>
                 </Collapse>
               </TableCell>
             </TableRow>
      </React.Fragment>
    );
  }
const multiDataSet = [
  {
    
    columns: [
        {title: "FS Lab Services",width: {wch: 50}, style:{alignment:{horizontal:'center'},font: {sz: "18", bold: true}}},//pixels width 
      
    ],
    data: summaryDate
},
  {
    columns: [
        {title: "Covid-19 Cases",width: {wch: 30}, style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},//pixels width 
        {title: "Panel", width: {wch: 50},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},//char width 
        {title: "Branch", width: {wch: 30},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "Positive", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
        {title: "Negative", width: {wch: 20},style:{alignment:{horizontal:'center'},font: {sz: "14", bold: true}}},
    ],
    data:data_export
}
];

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();
  
    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
  };
  const getData= async(data)=>{

    var rows_export=[]
    var d=from.toISOString()
   var from_=new Date(d).ddmmyyy()
   d=to.toISOString()
   var to_=new Date(d).ddmmyyy()
  var summary_data= [
   [ {value: "Summary of the Day "+from_+" - "+to_, width: {wch: 50}, style: {alignment:{horizontal:'center'},font: {sz: "18", bold: true}}},
  ]
]
setSummaryDate(summary_data)
   var res= await data.map((item)=>{
   var obj=[
  
  {value: item.covid_cases, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value:  item.panel, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value: item.branch, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value:   item.positive, style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
  {value:  item.negative, style: {alignment:{horizontal:'center'},font: {sz: "10",}} }

   ]
    rows_export.push(obj)

    })
    rows_export.push([])
    Object.keys(BranchCounter).map((item)=>{
      
      var obj1=[
      {value: item.split('MPL, ')[1], style: {alignment:{horizontal:'center'},font: {sz: "10",} }},
      {value:  BranchCounter[item], style: {alignment:{horizontal:'center'},font: {sz: "10",} }}
    ]

    rows_export.push(obj1)
    })

    Promise.all(res).then(()=>{
           setData_Export(rows_export)
  })
}
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
    
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BranchesLoadAllfromWebserver"
    ).then((res)=>res.json())
     .then((result)=>{
       setBranchDetail(result)
       var obj={}
       result.map((item)=>{
         obj[item.BranchID]=item.BranchName
       })
       setBranchbyName(obj)
       console.log(from)
       console.log(to)
      
       fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestsCovid19Exc?vQuery={%22RegDateFrom%22:%22"+from.toISOString()+"%22,%22RegDateTo%22:%22"+to.toISOString()+"%22,%22vBranchID%22:%220%22}"
       ).then((res)=>res.json())
        .then(async(result)=>{
        var cases=0
        var positive=0
        var negative=0
        var positive_cases={}
        var negative_cases={}
        var total_cases={}
var temp_date=""
var data=await result.map((item)=>{
  if(item.Test_Result=='Covid19 NOT Detected' && (item.Panel_Name==panel || panel=='All')  && (item.B_TiTLE==BranchbyName[branch] || branch=='All') ){
  cases=cases+1
  negative=negative+1
  temp_date=item.Result_Date.split('T')[0]
  if( total_cases[temp_date]==undefined){

   total_cases[temp_date]=1

 }else{
   total_cases[temp_date]= total_cases[temp_date]+1

 }
  if( negative_cases[temp_date]==undefined){

    negative_cases[temp_date]=1

  }else{
    negative_cases[temp_date]= negative_cases[temp_date]+1

  }
  if(BranchCounter[item.B_TiTLE]==undefined){
   BranchCounter[item.B_TiTLE]=1
  }else{
   BranchCounter[item.B_TiTLE]=BranchCounter[item.B_TiTLE]+1
  }
  return
  }
  if(item.Test_Result=='Covid19 Detected' && (item.Panel_Name==panel || panel=='All')  && (item.B_TiTLE==BranchbyName[branch]|| branch=='All')){
   cases=cases+1
   positive=positive+1
   temp_date=item.Result_Date.split('T')[0]
   if( total_cases[temp_date]==undefined){

     total_cases[temp_date]=1

   }else{
     total_cases[temp_date]= total_cases[temp_date]+1

   }
   if( positive_cases[temp_date]==undefined){

     positive_cases[temp_date]=1

   }else{
     positive_cases[temp_date]= positive_cases[temp_date]+1

   }
   if(BranchCounter[item.B_TiTLE]==undefined){
     BranchCounter[item.B_TiTLE]=1
    }else{
     BranchCounter[item.B_TiTLE]=BranchCounter[item.B_TiTLE]+1
    }
   return
  }
 })

          Promise.all(data).then(()=>{
            var the_rows=[{
              covid_cases:cases,
              positive:positive,
              negative:negative,
              branch:"All",
              panel:"All"


            }]
            var arr=[]
            console.log("Positive")
             var pos=0
            var neg=0
            Object.keys(total_cases).reverse().map((key)=>{
              pos=positive_cases[key]!=undefined ? positive_cases[key] : 0
              neg=negative_cases[key]!=undefined ? negative_cases[key] : 0
              arr.push({
                name:key, cases:total_cases[key],positive:pos , negative:neg
              })
                          })
            setDataGraph(
              arr
            )
            setRows(the_rows)
            setOrg_Rows(the_rows) 
            setLoading(false)
            getData(the_rows)   
          }
          )
          
         })
         
         
     })
    
     fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetAllPanelsList").then((res)=>res.json())
     .then((result)=>{
       setpanelList(result)
     })
  },[])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes(); 
    var hh = this.getHours();

    return [
        (hh > 9 ? '' : '0') + hh,
        (mm > 9 ? '' : '0') + mm
    ].join(':');
};

Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
        (dd > 9 ? '' : '0') + dd,
        (mm > 9 ? '' : '0') + mm,
        this.getFullYear()
    ].join('/');
};
  return (
    <Paper className={classes.root}>
    
      <GridContainer style={{padding:'2em'}}>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                  <InputLabel className={classes.label}>
                  <b>  From</b>
                  </InputLabel>
                  
                  <FormControl fullWidth>
                    <Datetime
                      inputProps={{ placeholder: "Summary of Date" }}
                      value={from}
                      timeFormat=""
                      onChange={(from)=>{
                        setFrom(from)
                      }}
                      dateFormat='DD-MM-YYYY'
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={2} sm={2} md={2} lg={2}>
                <InputLabel className={classes.label}>
                  <b> To</b>
                  </InputLabel>
                  
                  <FormControl fullWidth>
                    <Datetime
                      inputProps={{ placeholder: "Summary of Date" }}
                      value={to}
                      timeFormat=""
                      onChange={(to)=>{
                        setTo(to)
                      }}
                      dateFormat='DD-MM-YYYY'
                    />
                  </FormControl>
                  </GridItem>
                    <GridItem xs={2} sm={4} md={2} lg={2}>
                    <FormControl variant="filled" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="filled-age-native-simple">Branch</InputLabel>
            <Select
              native
              value={branch}
              onChange={(event)=>{
                const value = event.target.value;
                setBranch(value)
              }}
              inputProps={{
                name: 'status',
                id: 'filled-age-native-simple',
              }}
            >
              <option aria-label="Select" value="All" >All</option>
              {
                branchDetail.map((br)=>{
                return <option key={br.BranchID} value={br.BranchID}>{br.BranchName.split("MPL, ")[1]}</option>
                })
              }
            
            </Select>
          </FormControl>
                      </GridItem>
                 <GridItem xs={2} sm={2} md={2} lg={2}>
                 <FormControl variant="filled" className={classes.formControl} fullWidth>
            <InputLabel htmlFor="filled-age-native-simple">Panel</InputLabel>
            <Select
              native
              value={panel}
              onChange={(event)=>{
                const value = event.target.value;
                setPanel(value)
              }}
              inputProps={{
                name: 'status',
                id: 'filled-age-native-simple',
              }}
            >
              <option aria-label="Select" value="All" >All</option>
              {
                panelList.map((p)=>{
                return <option key={p.Panel_Code} value={p.Panel_Name}>{p.Panel_Name}</option>
                })
              }
            
            </Select>
          </FormControl>
                   </GridItem>
                  <GridItem xs={3} sm={3} md={2} lg={2} style={{margin:'auto'}} >
                    
                  <Button variant="contained" 
                  color="primary"
                  disabled={loading}
                   onClick={()=>{
                    setLoading(true)
                    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestsCovid19Exc?vQuery={%22RegDateFrom%22:%22"+from.toISOString()+"%22,%22RegDateTo%22:%22"+to.toISOString()+"%22,%22vBranchID%22:%22"+branch+"%22}"
                    ).then((res)=>res.json())
                     .then(async(result)=>{
                    
                      var cases=0
                      var positive=0
                      var negative=0
                  var positive_cases={}
                  var negative_cases={}
                  var total_cases={}
                  var temp_date=""
                      BranchCounter={}
                       var data=await result.map((item)=>{
                         if(item.Test_Result=='Covid19 NOT Detected' && (item.Panel_Name==panel || panel=='All')  && (item.B_TiTLE==BranchbyName[branch] || branch=='All') ){
                         cases=cases+1
                         negative=negative+1
                         temp_date=item.Result_Date.split('T')[0]
                         if( total_cases[temp_date]==undefined){
             
                          total_cases[temp_date]=1
                     
                        }else{
                          total_cases[temp_date]= total_cases[temp_date]+1
                     
                        }
                         if( negative_cases[temp_date]==undefined){
             
                           negative_cases[temp_date]=1
                      
                         }else{
                           negative_cases[temp_date]= negative_cases[temp_date]+1
                      
                         }
                         if(BranchCounter[item.B_TiTLE]==undefined){
                          BranchCounter[item.B_TiTLE]=1
                         }else{
                          BranchCounter[item.B_TiTLE]=BranchCounter[item.B_TiTLE]+1
                         }
                         return
                         }
                         if(item.Test_Result=='Covid19 Detected' && (item.Panel_Name==panel || panel=='All')  && (item.B_TiTLE==BranchbyName[branch]|| branch=='All')){
                          cases=cases+1
                          positive=positive+1
                          temp_date=item.Result_Date.split('T')[0]
                          if( total_cases[temp_date]==undefined){
             
                            total_cases[temp_date]=1
                       
                          }else{
                            total_cases[temp_date]= total_cases[temp_date]+1
                       
                          }
                          if( positive_cases[temp_date]==undefined){
              
                            positive_cases[temp_date]=1
                       
                          }else{
                            positive_cases[temp_date]= positive_cases[temp_date]+1
                       
                          }
                          if(BranchCounter[item.B_TiTLE]==undefined){
                            BranchCounter[item.B_TiTLE]=1
                           }else{
                            BranchCounter[item.B_TiTLE]=BranchCounter[item.B_TiTLE]+1
                           }
                          return
                         }
                        })
                        var branch_=BranchbyName[branch]
                        console.log(branch)
                        if(BranchbyName[branch]==null || BranchbyName[branch]==undefined){
                          branch_="All"
                        }
                        Promise.all(data).then(()=>{
                          var the_rows=[{
                            covid_cases:cases,
                            positive:positive,
                            negative:negative,
                            branch:branch_,
                            panel:panel
                          }]
                          var arr=[]
            var pos=0
            var neg=0
        
                          Object.keys(total_cases).reverse().map((key)=>{
                            pos=positive_cases[key]!=undefined ? positive_cases[key] : 0
                            neg=negative_cases[key]!=undefined ? negative_cases[key] : 0
                            arr.push({
                              name:key, cases:total_cases[key],positive:pos , negative:neg
                            })
                                        })
            setDataGraph(
              arr
            )
                          setRows(the_rows)
                          setOrg_Rows(the_rows) 
                          getData(the_rows)   
                          setLoading(false)

                        }
                        )
                               
                  })}}
                
                  >
                    Seach<SearchIcon />
                  </Button>
         
                  </GridItem>
                  <GridItem md={2} lg={2} style={{margin:'auto', float:'right'}} >
                  <ExcelFile element={ <Button
                color="primary"
                style={{backgroundColor:'green', color:"white"}}
               
              >
                Export Excel
              </Button> }>
                    <ExcelSheet dataSet={multiDataSet} name="COVID-19 Summary"/>
                </ExcelFile>
                 
                    </GridItem>
              </GridContainer>
        
     {loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
     <>
      <TableContainer className={classes.container}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
            <Row key={row.covid_cases} row={row} />
          ))
        }
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[10, 25, 100]}
      component="div"
      count={rows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
    </>
     }
   <GridContainer>
     <GridItem md={1}></GridItem>
     <GridItem md={10}>
      <LineChart width={900} height={500} data={graph_data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
    <Line type="monotone" dataKey="positive" stroke="red" />
    <Line type="monotone" dataKey="negative" stroke="blue" />
    <Line type="monotone" dataKey="cases" stroke="black" />
    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
  </LineChart>
  </GridItem>
  <GridItem md={1}></GridItem>
  </GridContainer>
    </Paper>
  );
}
