import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Card from "components/Card/Card.js";

import image1 from "assets/img/NewPic/fontDesk_FS.jpg"

import image2 from "assets/img/lobby.JPG";


import image3 from "assets/img/working.JPG";




import styles from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.js";
import { Height } from "@material-ui/icons";

const useStyles = makeStyles(styles);

export default function SectionCarousel() {
  const classes = useStyles();
  
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
      <div className={classes.container} style={{width:'100%'}}>
            <Card carousel style={{maxWidth:"900px", margin:"auto"}} >
              <Carousel  {...settings} speed={4000} autoplaySpeed={5} >
              <div>
                  <img src={image1} alt="First slide" className="slick-image" />
                  <div className="slick-caption">
                  </div>
                </div>
              
                <div>
                  <img src={image2} alt="Third slide" className="slick-image" />
                  <div className="slick-caption">
                    
                  </div>
                </div>
                <div>
                  <img src={image3} alt="Third slide" className="slick-image" />
                  <div className="slick-caption">
                    
                  </div>
                </div>
             
              </Carousel>
            </Card>
         
      </div>
    
  );
}
