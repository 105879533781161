import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Tracking from "./Tracking"
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import FreeHS_Msg from '../Components/FreeHS_Msg'
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import molecule_1 from 'assets/img/animations/virus (13).png';

import Temp from './Temp.js'

import molecule_6 from 'assets/img/animations/virus (6).png';

import molecule_7 from 'assets/img/animations/virus (9).png';

import COVID19_PCR from '../COVID19_PCR/Covid19_PCR.js'
import test_info from 'assets/img/test_info.png';


const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const [reports,setReports]=React.useState('')
  useEffect(()=>{
    if(localStorage.getItem('role')=='Admin'){
      setReports('true')
     }else{
      window.location.href="https://fslabs.com.pk/"
     }
  },[])
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/info.jpeg")} style={{maxHeight:'30em'}}>
        <div className={classes.container}>
          <GridContainer>
            
            <GridItem>
            <img src={molecule_1} className="funfact-two__virus" alt="" style={{
  width: '5em',
  height: 'auto', right:'30em'}}/>
       <img src={molecule_6} className="funfact-two__virus__5" alt="" style={{  width: '5em',
  height: 'auto',}}/>
       <img src={molecule_7} className="funfact-two__virus__7" alt="" style={{ width: '5em',
  height: 'auto',top:'10em'}}/>   
       
           
              <h1 className={classes.title}>Sample Tracking</h1>
              <h4>
               Information of Sender, Driver , Receiver etc.
              </h4>
              <br />
        
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{paddingTop:"1em", paddingBottom:"5em", paddingRight:'2em', paddingLeft:"2em"}} >
          <br />
          <img src={test_info} style={{
  width: '7em',}} class="center"/>
            {reports=='true' ? <Temp /> :<></>}
            <FreeHS_Msg />
         
        </div>
      </div>
      <Footer />
    </div>
  );
}
