import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import { Link } from "react-router-dom";

import clsx from 'clsx';
// @material-ui/icons
import Email from "@material-ui/icons/Email";
import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import url from '../../link'
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import Slide from "@material-ui/core/Slide";

import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import image from "assets/img/bg7.jpg";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  
export default function ResetPassword(props) {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [classicModal, setClassicModal] = React.useState(false);
  setTimeout(function() {
    setCardAnimation("");
  }, 700);
  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
    newpassword:'',
    shownewpassword:false,
    confirmPass:'',
    showConfrimPass:false

  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeNewPass = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowNewPass = () => {
    setValues({ ...values, shownewpassword: !values.shownewpassword });
  };

  const handleMouseDownNewPass= (event) => {
    event.preventDefault();
  };
  const handleChangeConfrimPass = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowConfrimPass = () => {
    setValues({ ...values, showConfrimPass: !values.showConfrimPass });
  };

  const handleMouseDownConfrimPass= (event) => {
    event.preventDefault();
  };

  const classes = useStyles();
  const [ServerResponse, setServerResponse]=React.useState('')
  const { ...rest } = props;
  return (
    <div>
      <Header
        absolute
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card className={classes[cardAnimaton]}>
                <form className={classes.form}>
                  <CardHeader  className={classes.cardHeader} style={{backgroundColor:'#1a5790'}}>
                    <h4 style={{color:'white'}}>Reset Password</h4>
                    
                  </CardHeader>
                  <CardBody>
                  <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password"> Current Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        
                <br />
                <br />
                
                <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">New Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.shownewpassword ? 'text' : 'password'}
            value={values.newpassword}
            onChange={handleChangeNewPass('newpassword')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPass}
                  onMouseDown={handleMouseDownNewPass}
                >
                  {values.shownewpassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
               
              
                <br />
                <br />
               
                <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
          <Input
            id="standard-adornment-password"
            type={values.showConfrimPass ? 'text' : 'password'}
            value={values.confirmPass}
            onChange={handleChangeConfrimPass('confirmPass')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfrimPass}
                  onMouseDown={handleMouseDownConfrimPass}
                >
                  {values.showConfrimPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
                
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    <Button color="#1a5790" size="lg" style={{backgroundColor:'#1a5790'}}
                     onClick={() => {
                       if(values.password.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Current Password !!')
                        return
                       }
                       if(values.newpassword.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter New Password !!')
                        return
                     
                       }
                       if(values.confirmPass.trim()==''){
                        setClassicModal(true)
                        setServerResponse('Kindly Enter Confirm Password !!')
                        return
                     
                       }
                       var log='ID'
                       var patient_id= localStorage.getItem('patient_id')
                       
                   fetch('https://eviewer.fslabs.com.pk:5443/4DACTION/WebLogin',{
                     method: 'POST',
                     body:'vUID=' + patient_id+ '&vPW=' + values.password + '&vRole=' + 'Patient' + '&vLoginType=' + log + "&vRandom=" + 21211
                   }).then((res)=>res.json()).then(async (resjson)=>{
                     if(resjson.Result){
                       await fetch('https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_ChangePW?vQuery='+values.newpassword + "&vUID=" + patient_id + "&vRole=" + "Patient").then(async ()=>{
                    setServerResponse('Password Reset Successfully !')
                    setClassicModal(true)
                    setTimeout(()=>{
                      window.location.reload()
                    },3500)
                    setTimeout(()=>{
                        localStorage.clear()
                        window.location.href=url+"/Login"
                    },3000)
                    return
                   })
                         }else{
                             setServerResponse('Sorry, Invalid Current Password !')
                              setClassicModal(true)
                              return
                         }
                     })                 
                      }}
                     >
                      Reset
                    </Button>
                    
                  </CardFooter>
                </form>
                
                    
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={4}>

            <Dialog
                  classes={{
                    root: classes.center,
                    paper: classes.modal
                  }}
                  open={classicModal}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => setClassicModal(false)}
                  aria-labelledby="classic-modal-slide-title"
                  aria-describedby="classic-modal-slide-description"
                >
                  <DialogTitle
                    id="classic-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                  >
                   
                    <h4 className={classes.modalTitle}><b>Reset Password</b></h4>
                  </DialogTitle>
                  <DialogContent
                    id="classic-modal-slide-description"
                    className={classes.modalBody}
                  >
                    <p>
                   {ServerResponse}
                    </p>
                  </DialogContent>
                  <DialogActions className={classes.modalFooter}>
                   
                    <Button
                      onClick={() => setClassicModal(false)}
                      color="danger"
                      simple
                    >
                      Ok
                    </Button>
                  </DialogActions>
                </Dialog>
              </GridItem>
          </GridContainer>

        </div>
        <Footer whiteFont />
      </div>
    </div>
  );
}
