import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "components/Grid/GridContainer.js";


import Slide from "@material-ui/core/Slide";

import { title } from "assets/jss/material-kit-react";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import DialogActions from "@material-ui/core/DialogActions";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import team1 from "assets/img_stock/bio_chem_1.JPG";
import team2 from "assets/img/faces/christian.jpg";
import team3 from "assets/img/faces/kendall.jpg";

import Typography from '@material-ui/core/Typography';
import Approved_Countries from './Approved_Countries'
import WorldWide from './WorldWide'

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function TeamSection() {
    const classes = useStyles();

    const [classicModal, setClassicModal] = React.useState(false);
    const [classicModalFlyDubai, setClassicModalFlyDubai] = React.useState(false);

    const [classicModalPIA, setClassicModalPIA] = React.useState(false);

    const imageClasses = classNames(
        classes.imgRaised,
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    return (
        <div className={classes.section}>
            <div>
                <GridContainer>
                    <GridItem xs={12} xm={12} md={12} lg={12} justify="center">
                    <button  className="Ads" style={{
                   fontSize: '1.3em', 
                   cursor:'pointer',
                   backgroundColor:'#1a5790' ,
                   borderWidth:0,
                    borderRadius: "calc(.25rem - 1px)",
                    padding:5,
                   boxShadow:
                       "10px 10px 10px 5px rgba(0.5, 0.5, 0.5, 0.2), 10px 10px 10px 5px rgba(0.5, 0.5, 0.5, 0.2)",
 
                    }}
                    onClick={() => { setClassicModal(true) }}>
                <h3 className={classes.title}  style={{
                        color:'white'
                      
                    }}>MPL Collection Points List For COVID-19 PCR</h3>
                    
                    </button>
                    </GridItem>
                </GridContainer>
                <GridContainer  style={{width:"100%", marginLeft:"0px", marginTop:5}} >
                <GridItem xs={2} sm={2} md={1} lg={1} ></GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12} >
                    <Accordion >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
              <h4 className={classes.title} style={{
                        
                    }}>Approved AirLines for COVID-19 PCR Country Wise</h4>
        </AccordionSummary>
        <AccordionDetails>
               
                 <Approved_Countries />
         </AccordionDetails>
         </Accordion>
                 </GridItem>
             
                 <GridItem xs={12} sm={12} md={12} lg={12} style={{
                     marginTop:10
                 }}>
                 <Accordion >
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                 <h4 className={classes.title}>Approved AirLines for Pre-Travel COVID-19 PCR Testing</h4>
                 </AccordionSummary>
        <AccordionDetails>
               
                 <WorldWide />
                 </AccordionDetails>
         </Accordion>
                 </GridItem>
                 <GridItem xs={2} sm={2} md={1} lg={1} ></GridItem>
                    {/* <GridItem xs={12} sm={12} md={6} lg={6}  >
                        <button
                        className={'bink'}
                            style={{
                            fontSize: '1.3em', 
                            cursor:'pointer',
                            backgroundColor: "white" ,borderWidth:0,  borderRadius: "calc(.25rem - 1px)",
                            boxShadow:
                                "10px 10px 10px 5px rgba(0.5, 0.5, 0.5, 0.2), 10px 10px 10px 5px rgba(0.5, 0.5, 0.5, 0.2)",
                }}
                            onClick={() => { setClassicModal(true) }}
                        >
                            <Card plain>
                                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                    <img src={require("assets/img/KSA.jpg")} alt="..." style={{
                                        width: "10em",
                                        height: "auto",
                                        borderRadius: "calc(.25rem - 1px)",
                                        boxShadow:
                                            "10px 10px 10px -5px rgba(0.5, 0.5, 0.5, 0.24), 10px 10px 10px -5px rgba(0.5, 0.5, 0.5, 0.2)",
                                    }} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                              
                                <h4 className={classes.cardTitle}>
                                Pre Travel COVID-19 PCR Testing for Saudi Arabia
                <br />
                                </h4>
                                </GridItem>
                            </Card>
                        </button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6} style={{
                             cursor:'pointer'
                    }}  >
                        <button
                            style={{ fontSize: '1.3vw',
                            cursor:'pointer',
                             backgroundColor: "white", borderWidth:0    , 
                            borderRadius: "calc(.25rem - 1px)",
                            boxShadow:
                                "10px 10px 10px 5px rgba(0.5, 0.5, 0.5, 0.2), 10px 10px 10px 5px rgba(0.5, 0.5, 0.5, 0.2)",
                    }}
                            onClick={() => { setClassicModalPIA(true) }}
                        >
                            <Card plain>
                                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                    <img src={require("assets/img/pia.jpg")} alt="..." style={{
                                        width: "10em",
                                        height: "auto",
                                        borderRadius: "calc(.25rem - 1px)",
                                        boxShadow:
                                            "10px 10px 10px -5px rgba(0.5, 0.5, 0.5, 0.24), 10px 10px 10px -5px rgba(0.5, 0.5, 0.5, 0.2)",
                                    }} />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                                <h4 className={classes.cardTitle}>
                                    Pre Travel COVID-19 PCR Testing for PIA
                
                                </h4>
                                </GridItem>
                            </Card>
                        </button>
                    </GridItem> */}

                </GridContainer>

                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={classicModal}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth={'lg'}
                    fullWidth={true}
                    onClose={() => setClassicModal(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >

                        <h4 className={classes.modalTitle}><b> Pre Travel COVID-19 PCR Testing Collection Points</b></h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}
                    >
                    
                    <GridContainer justify="center" style={{ textAlign: 'center' }}>
                 
                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                             Islamabad
     <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Nadeem Ahmad </h6>
                         <h6>Contact No : 0300-1118878</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>FS Lab Services 20-C Crescent Arcade, G-8 Markaz Islamabad
<br />
                                 <a href="tel:051111988988">UAN 051-111-988-988</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                             North Karachi
     <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Nazir </h6>
                         <h6>Contact No : 0300-9220895</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>FS Lab Services C/O Genose Diagnostic & Research Laboratory ,Mehran Extension, Ground Floor, Plot # FL-25 & 26, Block-16, Opposite Advance Lab Pvt Ltd, Gulshan e Iqbal, Karachi.
<br />
                                 <a href="tel: 03009220895">Phone  0300-9220895</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>
             
                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Peshawar
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Muhammad Aqeel</h6>
                         <h6>Contact No : 0340-2600312</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services Deans Trade Centre – Peshawar Saddar
<br />
                                 <a href="tel:03402600312">Phone 0340-2600312</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>


             </GridContainer>
             <GridContainer justify="center" style={{ textAlign: 'center' }}>

                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                             Karachi
     <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Baqi Bullah </h6>
                         <h6>Contact No : 0321-2448422</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>FS Lab Services K-Health Care Hospital
Nizam Lassi Wali Gali, Off Shahrah-e-Faisal, Karachi
<br />
                                 <a href="tel: 03212448422">Phone  0321-2448422</a>
                             </p>
                         </CardBody>
                     </Card>
                     
                 </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Multan
     <br />
                         </h4>
                         <h6>Focal Person Name :Mr. M Adeel Ahsan </h6>
                         <h6>Contact No : 0320-0552851, 0315-5372228</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>FS Lab Services Suit # 1, Mehar Centre, Nishtar Road, Multan
<br />
                                 <a href="tel:03155372228">Phone 0315-5372228</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>

                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Gujranwala
     <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Imran Tarar</h6>
                         <h6>Contact No : 0300-6474255</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                               Shop # 5, Jinnah Station Gujranwala
<br />
                                 <a href="tel:0553251122">Phone 0553251122</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>


             </GridContainer>
             <GridContainer justify="center" style={{ textAlign: 'center' }}>

                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Gujrat
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Muqadar Hussain </h6>
                         <h6>Contact No : 0347-3282908</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services Near Murghzar College, Shaheen Plaza Bhimber Road, Gujrat
<br />
                                 <a href="tel:03473282908">Phone 0347-3282908</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Sialkot
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Asim Aziz </h6>
                         <h6>Contact No : 0333-8686689</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>FS Lab Services Inside Kalsoom Hospital Airport Road, Sialkot
<br />
                                 <a href="tel:03338686689">Phone 0333-8686689</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>

                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                         </GridItem>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Dera Ghazi Khan
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Sharif Laghari</h6>
                         <h6>Contact No : 03146622072</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services Main Street Dubai Trade centre, Green Plaza near Ramzan Plaza Pul dat Jampur road D.G Khan
<br />
                                 <a href="tel:03146622072">Phone 0314-6622072</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>


             </GridContainer>
             
             <GridContainer justify="center" style={{ textAlign: 'center' }}>
                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Chakwal
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Israr ul Haq</h6>
                         <h6>Contact No : 0321-501014</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services Al-Rehman Plaza , Opposite Govt Post Graduate College, Chakwal
<br />
                                 <a href="tel:0321501014">Phone 0321-501014</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>
             


                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Faisalabad
 <br />
                         </h4>
                         <h6>Contact No : 0300-0123484</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services, Office # 01 KM Center Plaza , Near Main Trust Hospital, Sargodha Road - Faisalabad
<br />
                                 <a href="tel:03000123484">Phone 0300-0123484</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Jhelum
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Muhammad Sharif</h6>
                         <h6>Contact No :  0331-5677390</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services Office No 7, GTS plaza. GTS Chowk, Jhelum

<br />
                                 <a href="tel:03315677390">Phone 0331-5677390</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>

             </GridContainer>

           

            

             <GridContainer justify="center" style={{ textAlign: 'center' }}>
                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Mandi Bahauddin
 <br />
                         </h4>
                         <h6>Focal Person Name : MR. JAWAD UL HASSAN</h6>
                         <h6>Contact No : 0303-6875763</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
 FS Lab Services ,Office 5, Basement manzoor plaza old ,Rasool road, Mandi Bahauddin
<br />
                                 <a href="tel:0546505763">Phone 0546-505763</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>
           


                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Rawlakot
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Sardar Muhammad Usman</h6>
                         <h6>Contact No : 0333-3340968</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services , Near CMH, Rawalkot, AJK
<br />
                                 <a href="tel:03333340968">Phone 0333-3340968</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>


             </GridContainer>



             <GridContainer justify="center" style={{ textAlign: 'center' }}>
                 <GridItem xs={12} sm={12} md={4}>
                     <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Palandri
 <br />
                         </h4>
                         <h6>Focal Person Name : MR. Noman Waqar</h6>
                         <h6>Contact No : 0322-9325952</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services ,Jameel Market, Palandri, District Sudhnoti, AJK
<br />
                                 <a href="tel:03229325952">Phone 0322-9325952</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>
                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Multan-2
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Muhammad Javed Iqbal</h6>
                         <h6>Contact No : 0321-3811314</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services Opposite Chinab Hospital, Chongi no 1, Multan
<br />
                                 <a href="tel:03213811314">Phone 0321-3811314</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>


                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                         <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                         Rajanpur
 <br />
                         </h4>
                         <h6>Focal Person Name : Mr. Hasnain Raza</h6>
                         <h6>Contact No : 0336-6245062</h6>

                         <CardBody>
                             <p className={classes.description}>
                                 <b>Address : </b>
                                 FS Lab Services Opp Tehsil head quater Hospital Jampur..Distric Rajanpur
<br />
                                 <a href="tel:03366245062">Phone 0336-6245062</a>
                             </p>
                         </CardBody>
                     </Card>
                 </GridItem>


             </GridContainer>

             <GridContainer justify="center" style={{ textAlign: 'center' }}>
                
                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Jhang
            <br />
                                    </h4>
                                    <h6>Contact No : 0477621999</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services , Shop#8 Carown Plaza Gojra Road Nawaz Chowk Jhang Sadar.
<br />
                                            <a href="tel:0477621999">Phone 0477621999</a>
                                        </p>
                                    </CardBody>
                                </Card>
                 </GridItem>


                 <GridItem xs={12} sm={12} md={4}>
                 <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Talagang
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Malik Sabir Hussain , Yousaf Awan</h6>
                                    <h6>Contact No : 0331-3705128 , 0543-411088</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services , Near GPO Al-Karam Hospital Talagang.
<br />
                                            <a href="tel:0543411088">Phone 0543-411088</a>
                                        </p>
                                    </CardBody>
                                </Card>
                 </GridItem>


             </GridContainer>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>

                        <Button
                            onClick={() => setClassicModal(false)}
                            color="danger"
                            simple
                        >
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>
                {
                    // Pakistan International AirLine
                }

<Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={classicModalPIA}
                    TransitionComponent={Transition}
                    keepMounted
                    maxWidth={'lg'}
                    fullWidth={true}
                    onClose={() => setClassicModalPIA(false)}
                    aria-labelledby="classic-modal-slide-title"
                    aria-describedby="classic-modal-slide-description"
                >
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}
                    >

                        <h4 className={classes.modalTitle} style={{color:'red'}}><b>Pre Travel COVID-19 PCR Testing for PIA(Saudi Arabia, Dubai & Sharjah)</b></h4>
                    </DialogTitle>
                    <DialogContent
                        id="classic-modal-slide-description"
                        className={classes.modalBody}
                    >
                        <GridContainer >
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Instructions by PIA for Passengers
                <br />
                                </h4>
                              <p>For Further Details click on the following link: <br /> <a href="https://www.piac.com.pk/travel-advisory-updates" target="_blank">Travel Information</a></p>
                             

                            </GridItem>

                        </GridContainer >
                        <h2 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" , textAlign:'center'}}>
                                  Collection Points
                                </h2>
                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                 
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                        Islamabad
                <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Nadeem Ahmad </h6>
                                    <h6>Contact No : 0300-1118878</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>FS Lab Services 20-C Crescent Arcade, G-8 Markaz Islamabad
<br />
                                            <a href="tel:051111988988">UAN 051-111-988-988</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                        Karachi
                <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Baqi Bullah </h6>
                                    <h6>Contact No : 0321-2448422</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>FS Lab Services K-Health Care Hospital
Nizam Lassi Wali Gali, Off Shahrah-e-Faisal, Karachi
<br />
                                            <a href="tel: 03212448422">Phone  0321-2448422</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>



                        </GridContainer>
                        <GridContainer justify="center" style={{ textAlign: 'center' }}>

                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Temargarah
                <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Akhtar Munir </h6>
                                    <h6>Contact No : 0300-8585101</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Near China Market, Gor Gori Chowk, Temargarah
<br />
                                            <a href="tel:03008585101">Phone 0300-8585101</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Multan
                <br />
                                    </h4>
                                    <h6>Focal Person Name :Mr. M Adeel Ahsan </h6>
                                    <h6>Contact No : 0320-0552851, 0315-5372228</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>FS Lab Services Suit # 1, Mehar Centre, Nishtar Road, Multan
<br />
                                            <a href="tel:03155372228">Phone 0315-5372228</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Gujranwala
                <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Imran Tarar</h6>
                                    <h6>Contact No : 0300-6474255</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            Shop # 5, Jinnah Station Gujranwala
<br />
                                            <a href="tel:0553251122">Phone 0553251122</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>
                        <GridContainer justify="center" style={{ textAlign: 'center' }}>

                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Gujrat
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muqadar Hussain </h6>
                                    <h6>Contact No : 0347-3282908</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Near Murghzar College, Shaheen Plaza Bhimber Road, Gujrat
<br />
                                            <a href="tel:03473282908">Phone 0347-3282908</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Sialkot
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Asim Aziz </h6>
                                    <h6>Contact No : 0333-8686689</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>FS Lab Services Inside Kalsoom Hospital Airport Road, Sialkot
<br />
                                            <a href="tel:03338686689">Phone 0333-8686689</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>

                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>

                                    </GridItem>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Dera Ghazi Khan
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Sharif Laghari</h6>
                                    <h6>Contact No : 03146622072</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Main Street Dubai Trade centre, Green Plaza near Ramzan Plaza Pul dat Jampur road D.G Khan
<br />
                                            <a href="tel:03146622072">Phone 0314-6622072</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>
                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Batkhela
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Hameed khan </h6>
                                    <h6>Contact No : 03126363624</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Near Emergency Gate, Main Bazar Batkhela Opposite United CNG Pump, Kpk
<br />
                                            <a href="tel:03126363624">Phone 0312-6363624</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Mardan
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Tehseen ullah </h6>
                                    <h6>Contact No : 0311-9166094</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services opposite to Mardan medical complex, Mardan
<br />
                                            <a href="tel:03119166094">Phone 0311-9166094</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Abbottabad
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muhammad Sharif </h6>
                                    <h6>Contact No : 0331-5677390</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Office no 216, Iqbal shopping complex Fuwara chock, Abbottabad
<br />
                                            <a href="tel:0992330206">Phone 0992-330206</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>
                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Chakwal
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Israr ul Haq</h6>
                                    <h6>Contact No : 0321-501014</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Al-Rehman Plaza , Opposite Govt Post Graduate College, Chakwal
<br />
                                            <a href="tel:0321501014">Phone 0321-501014</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Swat
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Yaqoob Khan </h6>
                                    <h6>Contact No : 0301-8578300</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Airport Road, Near PSO station, Mingora, Swat
<br />
                                            <a href="tel:03018578300">Phone 0301-8578300</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Faisalabad
            <br />
                                    </h4>
                                    <h6>Contact No : 0300-0123484</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services, Office # 01 KM Center Plaza , Near Main Trust Hospital, Sargodha Road - Faisalabad
<br />
                                            <a href="tel:03000123484">Phone 0300-0123484</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>

                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Jhelum
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muhammad Sharif</h6>
                                    <h6>Contact No :  0331-5677390</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Office No 7, GTS plaza. GTS Chowk, Jhelum
 
<br />
                                            <a href="tel:03315677390">Phone 0331-5677390</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Nowshehra
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Mohsin Khan </h6>
                                    <h6>Contact No : 0331-5436315</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Near CMH main GT Road, Nowshehra Cantt
<br />
                                            <a href="tel:03315436315">Phone 0331-5436315</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Swabi
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Dr. Muhammad Arif</h6>
                                    <h6>Contact No : 0311-1011112</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Near National Bank Swabi branch,Swabi Jehangira main road, Dist Swabi
<br />
                                            <a href="tel: 03111011112">Phone 0311-1011112</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>

                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Peshawar
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muhammad Aqeel</h6>
                                    <h6>Contact No : 0340-2600312</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Deans Trade Centre – Peshawar Saddar
<br />
                                            <a href="tel:03402600312">Phone 0340-2600312</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Kohat
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Zawahir Rehman</h6>
                                    <h6>Contact No : 0333-9216778</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Near Moti Masjid MP Check post – Kohat Cantt
<br />
                                            <a href="tel: 03339216778">Phone 0333-9216778</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Battagram
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Sami Ul Haq</h6>
                                    <h6>Contact No : 0300-5480316</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Opposite Main Gate, DHQ Hospital, Battagram.
<br />
                                            <a href="tel:03005480316">Phone 0300-5480316</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>

                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Haripur
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muhammad Younis</h6>
                                    <h6>Contact No : 0337-9752876</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Khawaja Khan Market, GT road, Haripur.
<br />
                                            <a href="tel:03379752876">Phone 0337-9752876</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Mansehra
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muhammad Aurangzeb</h6>
                                    <h6>Contact No : 0302-8889226</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Office no 1, Khyber plaza, Near NADRA office, Mansehra.
 
<br />
                                            <a href="tel:0997544130">Phone 0997-44130</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Shabqadar
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muhammad Ishaq</h6>
                                    <h6>Contact No : 0333-9241808</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services, 1st Floor, Shop-1 Near Karchi Medicine, Mian Chowk Shabqadar Bazar
 
<br />
                                            <a href="tel:03339241808">Phone 0333 9241808</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>
                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Mandi Bahauddin
            <br />
                                    </h4>
                                    <h6>Focal Person Name : MR. JAWAD UL HASSAN</h6>
                                    <h6>Contact No : 0303-6875763</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
            FS Lab Services ,Office 5, Basement manzoor plaza old ,Rasool road, Mandi Bahauddin
<br />
                                            <a href="tel:0546505763">Phone 0546-505763</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Bannu
            <br />
                                    </h4>
                                    <h6>Focal Person Name : MR. ASMAT</h6>
                                    <h6>Contact No : 0333-3187723</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Opposite mission hospital Bannu
<br />
                                            <a href="tel:03333187723">Phone 0333-3187723</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Rawlakot
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Sardar Muhammad Usman</h6>
                                    <h6>Contact No : 0333-3340968</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services , Near CMH, Rawalkot, AJK
<br />
                                            <a href="tel:03333340968">Phone 0333-3340968</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>



                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Palandri
            <br />
                                    </h4>
                                    <h6>Focal Person Name : MR. Noman Waqar</h6>
                                    <h6>Contact No : 0322-9325952</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services ,Jameel Market, Palandri, District Sudhnoti, AJK
<br />
                                            <a href="tel:03229325952">Phone 0322-9325952</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Multan-2
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Muhammad Javed Iqbal</h6>
                                    <h6>Contact No : 0321-3811314</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Opposite Chinab Hospital, Chongi no 1, Multan
<br />
                                            <a href="tel:03213811314">Phone 0321-3811314</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Rajanpur
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Mr. Hasnain Raza</h6>
                                    <h6>Contact No : 0336-6245062</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Opp Tehsil head quater Hospital Jampur..Distric Rajanpur
<br />
                                            <a href="tel:03366245062">Phone 0336-6245062</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>

                        <GridContainer justify="center" style={{ textAlign: 'center' }}>
                            <GridItem xs={12} sm={12} md={4}>
                                <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Hangu
            <br />
                                    </h4>
                                    <h6>Focal Person Name : MR. SABIR</h6>
                                    <h6>Contact No : 0300-8808872</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services Near Type D hospital, Thall City, District Hangu.
<br />
                                            <a href="tel:03008808872">Phone 0300-8808872</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Jhang
            <br />
                                    </h4>
                                    <h6>Contact No : 0477621999</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services , Shop#8 Carown Plaza Gojra Road Nawaz Chowk Jhang Sadar.
<br />
                                            <a href="tel:0477621999">Phone 0477621999</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                            <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                    <h4 style={{ ...title, marginBottom: "1rem", marginTop: "30px", minHeight: "32px", textDecoration: "none" }}>
                                    Talagang
            <br />
                                    </h4>
                                    <h6>Focal Person Name : Malik Sabir Hussain , Yousaf Awan</h6>
                                    <h6>Contact No : 0331-3705128 , 0543-411088</h6>

                                    <CardBody>
                                        <p className={classes.description}>
                                            <b>Address : </b>
                                            FS Lab Services , Near GPO Al-Karam Hospital Talagang.
<br />
                                            <a href="tel:0543411088">Phone 0543-411088</a>
                                        </p>
                                    </CardBody>
                                </Card>
                            </GridItem>


                        </GridContainer>
                    </DialogContent>
                    <DialogActions className={classes.modalFooter}>

                        <Button
                            onClick={() => setClassicModalPIA(false)}
                            color="danger"
                            simple
                        >
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>



            </div>
        </div>
    );
}
