import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position:'relative',
    textAlign:'left'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),

    fontWeight:"bold"
  },
}));

export default function SimpleAccordion() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>PIA</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         <ol>
           <li>Kingdom of Saudi Arabia</li>
           <li>Dubai</li>
           <li>Sharjah</li>
           <li>Muscat/Oman</li>
           <li>Afghanistan</li>
           <li>France</li>
           <li>Kyrgystan</li>
           <li>United Kingdom (UK)</li>
           <li>Canada</li>
           </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Qatar Airways</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          World Wide Destinations Except South Korea,China & Hong Kong
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Turkish Airlines</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          World Wide Destinations Except South Korea, China & Hong Kong
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Etihad Airways</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          World Wide Destinations Except South Korea, China & Hong Kong
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Serene Air</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>Kingdom of Saudi Arabia</li>
           <li>UAE</li>
           </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Emirates Air</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          World Wide Destinations Except China & Hongkong
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>FlyDubai</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          World Wide Destinations Except China & Hongkong
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Air Arabia</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          World Wide Destinations Except China & Hongkong
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>British Airways</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          United Kingdom (UK)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Virgin Atlantic</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          United Kingdom (UK)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Oman Air</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         <ol>
           <li>Kingdom of Saudi Arabia (KSA)</li>
           <li>Oman/Muscat</li>
     
           </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Gulf Air</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         <ol>
           <li>Kingdom of Saudi Arabia (KSA)</li>
           <li>Oman/Muscat</li>
     
           </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Kuwait Airways</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Kingdom of Saudi Arabia (KSA)
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Salam Air</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>Kingdom of Saudi Arabia</li>
           <li>Oman</li>
           <li>Doha</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
 
    
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Saudi Airline</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Kingdom of Saudi Arabia (KSA)
          </Typography>
        </AccordionDetails>
      </Accordion>
    
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Air Blue</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ol>
           <li>Kingdom of Saudi Arabia</li>
          <li>United Arab Emirates (UAE) - All Destinations</li>
          </ol>
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    
      
    </div>
  );
}
