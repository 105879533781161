import React, { useEffect } from 'react';

import { CSVLink } from "react-csv";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfSharpIcon from '@material-ui/icons/PictureAsPdfSharp';
import Button from '@material-ui/core/Button';


import Datetime from 'react-datetime'
import ReactLoading from 'react-loading';

import SearchIcon from '@material-ui/icons/Search';

import Select from '@material-ui/core/Select';

import ReactExport from 'react-data-export';

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";


import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import TextField from '@material-ui/core/TextField';
import AutorenewIcon from '@material-ui/icons/Autorenew';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const columns = [
  { label: 'Report No', id: 'report_no' },
  { label: 'Name', id: 'name' },
  { label: 'Father Name', id: 'father_name' },
  { label: 'Husband Name', id: 'husband_name' },
  { label: 'Passport No', id: 'passport' },
  { label: 'CNIC', id: 'cnic' },
  { label: 'Mobile No', id: 'mobile_no' },
  { label: 'Airline', id: 'airline' },
  { label: 'Flight#', id: 'flight_no' },
  { label: 'Ticket No', id: 'ticket_no' },
  { label: 'Sample Collection Date Time', id: 'sample_date_time' },
  { label: 'Report Date Time', id: 'report_date_time' },
  { label: 'Flight Date Time', id: 'flight_date_time' },
  { label: 'Result', id: 'result' },
  { label: 'Collection Point', id: 'collection_point' },

];
const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 1000,
  },
});
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return hours + ':' + minutes;
}


export default function Reports() {
  const classes = useStyles();
  const [to , setTo]=React.useState(new Date)
  const [from , setFrom]=React.useState(new Date())
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  const [org_rows, setOrg_Rows] = React.useState([]);
  const [loading, setLoading] = React.useState(true)
  const [branchDetail, setBranchDetail] = React.useState([]);
  const [data_export, setData_Export] = React.useState([])
  const [selectedDate, setSelectedDate] = React.useState(new Date())
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const [branch, setBranch] = React.useState('All')
  const [state, setState] = React.useState({
    status: 'All',
  });


  const multiDataSet = [
    {
      columns: [
        { title: "Report No", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true, color: "87CEEB", underline: true } } },//pixels width 
        { title: "Name", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },//char width 
        { title: "Father Name", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Husband Name", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Passport No", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "CNIC", width: { wch: 40 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Mobile No", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Airline", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight#", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Ticket No", width: { wch: 30 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Sample Collection Date Time", width: { wch: 40 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Report Date Time", width: { wch: 40 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Flight Date Time", width: { wch: 40 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Result", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },
        { title: "Collection Point", width: { wch: 20 }, style: { alignment: { horizontal: 'center' }, font: { sz: "18", bold: true } } },

      ],
      data: data_export
    }
  ];

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  const getData = async (data) => {


    var rows_export = []
   

    var res = await data.map((item) => {
      var obj = [
        { value: item.report_no, style: { font: { sz: "14" }, alignment: { horizontal: 'center' } } },
        { value: item.name, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.father_name, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.husband_name, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.passport, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.cnic, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.mobile_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.airline, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.ticket_no, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.sample_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.report_date_time, style: { font: { sz: "14", }, alignment: { horizontal: 'center' } } },
        { value: item.flight_date_time, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } },
        { value: item.result, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } },
        { value: item.collection_point, style: { font: { sz: "14", color: '#87ceeb' }, alignment: { horizontal: 'center' } } },

      ]
      rows_export.push(obj)

      return

    })

    Promise.all(res).then(() => {
      setData_Export(rows_export)
    })
  }
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  useEffect(() => {
      fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/KSA_ReportsCovidPositiveAlHarm?vQuery={%22RegDateFrom%22:%22" + to.toISOString() + "%22,%22RegDateTo%22:%22" + from.toISOString() + "%22,%22vBranchID%22:%220%22}"
        ).then((res) => res.json())
          .then((result) => {
            var the_rows = []
            result.map((data) => {

              var res_date = new Date(data.result_date).ddmmyyy()
              var flight_date = new Date(data.flight_date).ddmmyyy()
              var test_res = data.Test_Result

              if (data.flight_date == "0000-00-00T00:00:00.000Z") {
                flight_date = ""
              }
              if (data.result_date == "0000-00-00T00:00:00.000Z") {
                res_date = ""
              }

              if (data.Test_Result == "Covid19 NOT Detected") {
                test_res = 'negative'
              }
              if (data.Test_Result == "Covid19 Detected") {
                test_res = 'positive'
              }
              var booking_date = new Date(data.sample_date).ddmmyyy()
              var booking_time = msToTime(data.sample_time)
              if (test_res == "") {
                test_res = "Results not Ready Yet"
              }


              var br_title=""
 
              if(data.Collection_Point=="METROPOLE"){
                br_title="Islamabad"
              }else{
      
             br_title=data.Collection_Point.split('MPL,')[1]
              }
              if(data.Collection_Point.toLowerCase().includes('genomic rwp') || data.Collection_Point.toLowerCase().includes('ddlc') || data.Collection_Point.toLowerCase().includes('biogene') || data.Collection_Point.toLowerCase().includes('device solutions') || data.Collection_Point.toLowerCase().includes('medikay') || data.Collection_Point.toLowerCase().includes('blue area') || data.Collection_Point.toLowerCase().includes('mpl-mhc')){
                br_title="Islamabad"
                          }
                          if(data.Collection_Point.toLowerCase().includes('6th road rwp') || data.Collection_Point.toLowerCase().includes('falak air') || data.Collection_Point.toLowerCase().includes('abrar')   ){
                            br_title="Rawalpindi"
                          }

              var flight_time = msToTime(data.flight_time)
              var result_time=msToTime(data.result_time)
             
              var obj = {
                report_no: data.Report_No,
                name: data.Passenger_Name,
                father_name:data.father_name,
                husband_name:data.husband_name,
                passport: data.Passport_No,
                cnic:data.CNIC,
                mobile_no: data.mobile_no,
                airline:data.Airline,
                flight_no: data.flight_no,
                ticket_no:data.ticket_no,
                sample_date_time:booking_date+" "+booking_time,
                report_date_time:res_date+" "+result_time,
                flight_date_time:flight_date+" "+flight_time,
                result: data.Report_Status,
                collection_point:br_title,
              }
             
              the_rows.push(obj)

            })

            setRows(the_rows)
            setOrg_Rows(the_rows)
            getData(the_rows)
            setLoading(false)
          })
  
  }, [])
  Date.prototype.hhmm = function () {
    var mm = this.getMinutes();
    var hh = this.getHours();

    return [
      (hh > 9 ? '' : '0') + hh,
      (mm > 9 ? '' : '0') + mm
    ].join(':');
  };

  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      this.getFullYear()
    ].join('/');
  };
  return (
    <Paper className={classes.root}>

      <GridContainer style={{ padding: '2em' }}>
        <GridItem xs={6} sm={6} md={3} lg={3}>
          <InputLabel className={classes.label}>
            <b>  TO</b>
          </InputLabel>

          <FormControl fullWidth>
            <Datetime
              inputProps={{ placeholder: "Summary of Date" }}
              value={to}
              timeFormat=""
              onChange={(date) => {
                setTo(date)
              }}
              dateFormat='DD-MM-YYYY'
            />
          </FormControl>
          </GridItem>
          <GridItem xs={6} sm={6} md={3} lg={3}>
          <InputLabel className={classes.label}>
            <b>  FROM</b>
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              inputProps={{ placeholder: "Summary of Date" }}
              value={from}
              timeFormat=""
              onChange={(date) => {
               setFrom(date)
              }}
              dateFormat='DD-MM-YYYY'
            />
          </FormControl>
        </GridItem>



        <GridItem xs={6} sm={6} md={3} lg={3} style={{ margin: 'auto' }} >

          <Button variant="contained" color="primary" disableElevation
            onClick={() => {
              setLoading(true)
              fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/KSA_ReportsCovidPositiveAlHarm?vQuery={%22RegDateFrom%22:%22" + to.toISOString() + "%22,%22RegDateTo%22:%22" + from.toISOString() + "%22,%22vBranchID%22:%22" + branch + "%22}"
              ).then((res) => res.json())
                .then((result) => {
                  var the_rows = []
                  

                  result.map((data) => {

                    var res_date = new Date(data.result_date).ddmmyyy()
                    var flight_date = new Date(data.flight_date).ddmmyyy()
                    var test_res = data.Test_Result

                    if (data.flight_date == "0000-00-00T00:00:00.000Z") {
                      flight_date = ""
                    }
                    if (data.result_date == "0000-00-00T00:00:00.000Z") {
                      res_date = ""
                    }

                    if (data.Test_Result == "Covid19 NOT Detected") {
                      test_res = 'negative'
                    }
                    if (data.Test_Result == "Covid19 Detected") {
                      test_res = 'positive'
                    }
                    var booking_date = new Date(data.sample_date).ddmmyyy()
                    var booking_time = msToTime(data.sample_time)
                    if (test_res == "") {
                      test_res = "Results not Ready Yet"
                    }


                    var br_title=""
       
                    if(data.Collection_Point=="METROPOLE"){
                      br_title="Islamabad"
                    }else{
            
                   br_title=data.Collection_Point.split('MPL,')[1]
                    }
                    if(data.Collection_Point.toLowerCase().includes('genomic rwp') || data.Collection_Point.toLowerCase().includes('ddlc') || data.Collection_Point.toLowerCase().includes('biogene') || data.Collection_Point.toLowerCase().includes('device solutions') || data.Collection_Point.toLowerCase().includes('medikay') || data.Collection_Point.toLowerCase().includes('blue area') || data.Collection_Point.toLowerCase().includes('mpl-mhc')){
                      br_title="Islamabad"
                                }
                                if(data.Collection_Point.toLowerCase().includes('6th road rwp') || data.Collection_Point.toLowerCase().includes('falak air') || data.Collection_Point.toLowerCase().includes('abrar')   ){
                                  br_title="Rawalpindi"
                                }

                    var flight_time = msToTime(data.flight_time)
                    var result_time=msToTime(data.result_time)
                   
                    var obj = {
                      report_no: data.Report_No,
                      name: data.Passenger_Name,
                      father_name:data.father_name,
                      husband_name:data.husband_name,
                      passport: data.Passport_No,
                      cnic:data.CNIC,
                      mobile_no: data.mobile_no,
                      airline:data.Airline,
                      flight_no: data.flight_no,
                      ticket_no:data.ticket_no,
                      sample_date_time:booking_date+" "+booking_time,
                      report_date_time:res_date+" "+result_time,
                      flight_date_time:flight_date+" "+flight_time,
                      result: data.Report_Status,
                      collection_point:br_title,
                    }
                   
                    the_rows.push(obj)

                  })

                  setRows(the_rows)
                  setOrg_Rows(the_rows)
                  getData(the_rows)
                  setLoading(false)
                })
            }}

          >
            Seach<SearchIcon />
          </Button>

        </GridItem>
     
        <GridItem md={3} lg={3} style={{ margin: 'auto', float: 'right' }} >
          <ExcelFile filename="KSA Positive Cases" element={<Button
            color="primary"
            style={{ backgroundColor: 'green', color: "white" }}

          >
            Export Excel
              </Button>}>
            <ExcelSheet dataSet={multiDataSet} name="KSA Positive Cases" />
          </ExcelFile>

        </GridItem>
      </GridContainer>

      {loading ?
        <center>
          <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
        </center>
        :
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code + Math.random()} >
                      {columns.map((column) => {
                        const value = row[column.id];

                        return (

                          <TableCell key={column.id} align={column.align} >
                            {value}
                          </TableCell>

                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      }
    </Paper>
  );
}
